import React from 'react'
import './eventCard.css'
import useTranslate from '../../customHook/useTranslate'

function Masks() {
    useTranslate();

    return (
        <>  
            <div className='eventCard langBlock cn'>
                <h4>口罩派发</h4>
                <p>新冠肺炎疫情在美国持续扩散，加州成为全美确诊第一州，为保障留美的USC学生的防疫需要，
                    CGSA职发部决定向留学生免费发放防疫口罩和消毒洗手液。考虑到疫情期间聚集有风险，
                    我们在2020年10月31日和11月1日的下午分别在USC Village和Lorenzo Parking分批次派发物资，
                    每个同学可领20个一次性口罩，10 个N95口罩和2瓶洗手液。这次活动希望帮助同学们共同抗疫、
                    保持健康积极的状态。最后感谢Guardians of The Angeles Charitable Foundation的企业家们对我们的物资捐赠和对留学生的关心与爱护！
                </p>
            </div>
            <div className='eventCard langBlock en'>
                <h4>Masks Delivery</h4>
                <p>As the COVID-19 epidemic continues to spread in the United States, 
                    California has become the first state in the country to be diagnosed. 
                    To meet the epidemic prevention needs of USC students, CGSA decided to distribute 
                    free anti-epidemic masks and hand sanitizer to international students. Considering 
                    the risk of gathering during the epidemic, we will distribute supplies in batches in 
                    USC Village and Lorenzo Parking on the afternoon of October 31 and November 1, 2020 
                    respectively. Each student can receive 20 disposable masks, 10 N95 masks and 2 bottles 
                    of hand sanitizer. We hope to try our best to help students fight the virus together and 
                    keep healthy and positive. Lastly，we appreciate the generous donation and care from 
                    entrepreneurs of The Guardians of The Angeles Charitable Foundation.
                </p>
            </div>
            <img src={require('./image/masks.png').default} alt=""/>
        </>
    )
}

export default Masks
