import React, { Component } from 'react';
import {Container, Row, Col,ButtonToolbar,Button} from 'react-bootstrap';
import DepartmentInfo from './DepartmentInfo';
import axios from 'axios';
import Util from './data_storage/ImportHtml.json';
import { translate } from './general/translate'

const buttonCenter={
    margin: 'auto'
}
const sectionStyle={
    padding: "70px 0"
}
const rowStyle = {
    marginBottom: "50px"
}

class DepartmentList extends Component{
    constructor(props){
        super(props)
        this.state = {
            depts:[]
        }
    }

    componentDidMount() {
        axios.get(Util[0].util_getpost.root+Util[0].util_getpost.department+'/all')
                .then(response => {
                    console.log(response.data)
                    this.setState({depts:response.data})
                })
                .catch(error =>{
                    console.log(error)
                })
    }
    render(){
        var row1 = []
        var row2 = []
        var DepartmentDetail = this.state.depts;

        if (DepartmentDetail.length !==0){
            for (let i=0; i<3; i++) {
                row1.push(
                    <Col sm="6" md="4" key={i}>
                        <DepartmentInfo
                            index={i+""}
                            department={DepartmentDetail[i].deptName}
                            departmentEn={DepartmentDetail[i].deptNameEn}
                            description={DepartmentDetail[i].shortInfo}
                            descriptionEn={DepartmentDetail[i].shortInfoEn}/>
                    </Col>)
            }
            for (let i=3; i<6; i++) {
                row2.push(
                    <Col sm="6" md="4" key={i}>
                        <DepartmentInfo
                            index={i+""}
                            department={DepartmentDetail[i].deptName}
                            departmentEn={DepartmentDetail[i].deptNameEn}
                            description={DepartmentDetail[i].shortInfo}
                            descriptionEn={DepartmentDetail[i].shortInfoEn}/>
                    </Col>)
            }
        }

      return (
        <section style={sectionStyle}>
            <div>
                <h2 style={sectionStyle}>{translate('部门介绍')}</h2>
                <Container>
                    <Row style={rowStyle}>
                        {row1}
                    </Row>
                    <Row>
                        {row2}
                    </Row>
                </Container>
                <ButtonToolbar>
                    <Button variant="danger" style={buttonCenter} href="/about_us">{translate('了解更多')}</Button>
                </ButtonToolbar>
            </div>
        </section>
        )
    }
  }
export default DepartmentList;
