import React from 'react'
import './eventCard.css'
import useTranslate from '../../customHook/useTranslate'

function CoffeeChat3() {
    useTranslate();

    return (
        <>  
            <div className='eventCard langBlock cn'>
                <h4>Coffee Chat 疫情特别版</h4>
                <img src={require('./image/coffeechat4.png').default} alt=""/>
                <p>2020年4月18日，CGSA推出了疫情期间特别版Cloud Coffee Chat。 为了让同学们在家的同时依然有机会与行业前辈们交流，我们邀请到了就业于Google的软件工程师Yimin Ding， HIKVISION的法律顾问Jun Liu，EGhunter的联合创始人Junyu Lin，PIMCO的Cody Lo，以及Ernst &Young 的商务分析师Jolee Zhao来给大家分享他们的求学经历及职场的经验。</p>
                <p>同时职发部的部员还通过具体例子和大家分享了如何书写cover letter、如何更好地回答面试过程中的behavioral questions以。会议的最后，同学们根据自己的专业和兴趣去到不同的Zoom Room，就自己感兴趣的问题和嘉宾进行了一对一的交流！</p>
            </div>
            <div className='eventCard langBlock en'>
                <h4>Cloud Coffee Chat</h4>
                <img src={require('./image/coffeechat4.png').default} alt=""/>
                <p>The Career Development Group of CGSA presented a very special Cloud Coffee Chat during the pandemic on April 18th, 2020. To give more chances for our students to network with working professionals, we invited software engineer Yimin Ding from Google, legal counsel Jun Liu from HIKVISION, real return portfolio analyst Cody Lo from PIMCO, business analyst Joleen Zhao from Ernst & Young, and Junyu Lin, cofounder of EGhunter, a HR company.</p>
                <p>At the beginning of the Coffee Chat, members from the Career Development Group delivered a presentation on how to write a cover letter and how to answer the behavioral questions during the interview. Five panelists then shared their experiences and advice on school and career development. Lastly, students were separated into several Zoom breakout rooms and talked to alumni in the industry that they were interested in. </p>

            </div>
        </>
    )
}

export default CoffeeChat3
