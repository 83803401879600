import React from "react";
import { translate } from '../general/translate'
import useTranslate from '../customHook/useTranslate'

const contentStyle = {
    textAlign: "left",
}

function CGSAIntro() {
    useTranslate();

    return (
        <div className="CGSA_intro">
            <h2>{translate("组织介绍")}</h2>
            <div style={contentStyle}>
                <p className="langBlock cn">
                    我们是南加州大学中国研究生学生会，简称USC-CGSA。
                    我们的宗旨是为南加大的中国研究生，博士生，MBA提供一个专注于就业，
                    学术和社交相关的平台，并为广大留学生提供力所能及的留学问题，法律，手续方面的帮助。
                    </p>
                <p className="langBlock en">
                    CGSA is the Chinese graduate student association at USC, uniting Chinese
                    background graduate students across the USC grad schools. With the goal of building
                    a strong network and to unify the Chinese graduate student community at USC,
                    CGSA will provide both social and professional opportunities.
                    </p>
                <img src="https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/CGSAALL.jpg" alt="jiti" />

            </div>
        </div>
    )
}

export default CGSAIntro
