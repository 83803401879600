import React from 'react'
import './career.css'
import ResourceCard from './ResourceCard'

import linkedin from './image/linkedin.png'
import indeed from './image/indeed.png'
import glassdoor from './image/Glassdoor.png'
import careerbuilder from './image/careerbuilder.jpeg'
import monster from './image/monster.jpg'

function USResource() {
    return (
        <div className="career resource">
            <ResourceCard 
                image={linkedin}
                description="业界最厉害的职业经理人社交网站，来自全球500强的职业经理人都在此展示自己的履历和市场成绩。
                            如果你想进入自己的DREAMING FIRM，上这个网站可以了解目标公司的员工，熟悉他们的工作内容，
                            与他们交谈，甚至都可以获得非常珍贵的内部推荐机会。在自己的简历上展示LinkedIn帐号，
                            已经成为一种专业的象征，玩SNS，除了消磨时光，更别忘了为自己积累人脉，赶紧动手吧！"
                link="https://www.linkedin.com/"
                imgStyle={{padding:'80px 76px'}}
            />
            <ResourceCard 
                image={indeed}
                description="Indeed是一个招聘信息垂直搜索引擎。自从2004年成立以来，Indeed招聘网为求职者提供了数以百万计的免费职缺信息，
                            信息来源于数千万家招聘网站，公司企业，以及求职机构。求职者无需单独访问各个招聘资源，只需要登陆，
                            并在职位关键词搜索框中填入您想搜索的工作的关键字、在工作地点搜索框中填入您想找工作的省市区县的名字，
                            便可以浏览所有符合职位的招聘信息和申请方式。同时，越来越多的职位支持Indeed 一键申请功能，您可以直接发送 Indeed 
                            简历给这些职位的雇主。"
                link="https://www.indeed.com/"
                imgStyle={{padding:'21px 0px'}}
            />
            <ResourceCard 
                image={glassdoor}
                description="Glassdoor现在是继Indeed.com之后美国第二大最受欢迎的招聘网站。该网站上面有各个大公司，
                            各种职位的常见面试问题，可为求职者提供面试准备。同时Glassdoor也是一个查找公司薪水、升迁路径、
                            内部消息和员工结构等重要信息的好去处，上面有大量的现任/前任员工大爆料，有关其雇主的详细评论-列出利弊，
                            以及有关他们的首席执行官的信息。对于求职者来说，Glassdoor已成为重要的在线资源。"
                link="https://www.glassdoor.com/index.htm"
            />
            <ResourceCard
                image={careerbuilder}
                description="CareerBuilder是北美最大的招聘网站运营商，招聘职位超过160万个，
                            CareerBuilder已建立了一个全球网络平台，拥有2000家以上的合作伙伴，在55个国家，
                            跨越5大洲使客户能获得最大和最多样化的人才。周围用CB的人普遍反映，这家网站的回复率是最高的，
                            可以或者不可以都会给一个答复，妈妈再也不用担心我的简历石沉大海。"
                link="https://www.careerbuilder.com/"
                imgStyle={{padding:'78px 0px'}}
            />
            <ResourceCard
                image={monster}
                description="Monster作为全球最早、规模最大的招聘网站，提供由招聘代理、线上招聘、
                            猎头服务和招聘黄页广告构成的全方位的全球招聘解决方案，已在全球26个国家设立了分公司或办事处，
                            使用22种当地语言开设网站。除了搜索工作以外，改简历、面试或者其他和求职有关的信息都可以在这个网站找到。
                            资源之丰富绝对值得你花点时间看看。"
                link="https://www.monster.com/"
                imgStyle={{padding:'50px 0px'}}
            />
        </div>
    )
}

export default USResource;
