import React from 'react'
import Gallery from './Gallery'

const images = [
    {
        src:require('./images/carnival1.jpeg'),
        height:300
    },
    {
        src:require('./images/carnival2.jpeg'),
        height:300
    },
    {
        src:require('./images/carnival3.jpeg'),
        height:200
    },
    {
        src:require('./images/carnival5.jpeg'),
        height:210
    },
    {
        src:require('./images/carnival7.jpeg'),
        height:200
    },
    {
        src:require('./images/carnival6.jpeg'),
        height:200
    },
     {
        src:require('./images/carnival4.jpeg'),
        height:200
    },
]

function CGSAcarnival() {
    return (
        <div className='carnival_content'>
            <h4>CGSA Carnival</h4>
            <div className="gallery">
                <Gallery imgarr={images} />
            </div>
        </div>
    )
}

export default CGSAcarnival
