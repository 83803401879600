import React from 'react';
// import CGSAVideo from '../../video/2018fall.mp4';
// import CGSAVideo from '../../video/cgsa_2021.mp4'
import { translate } from './translate'

const sectionStyle={
  padding: "70px 0"
}

class Video extends React.Component {
  render() {
    return (
        <div id="theVideo" style={sectionStyle}>
            <h2>{translate('最新活动')}</h2>
            <p>{translate('来了解我们的最新活动吧！')}</p>
            <video controls src="https://usccgsa-web.s3.amazonaws.com/cgsa_Video.mp4" type="video/mp4" width="50%"/>
        </div>
    );
  }
}
export default Video;