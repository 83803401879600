import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import axios from "axios";
import Post from "./Post";
import "./page.css";
import { translate } from "../general/translate";
import ScrollButton from "../general/ScrollButton";

const WelfarePage = (props) => {
    const [category, setCategory] = useState([]);
    const [post, setPost] = useState([]);

    const handleClick = (item) => {
        setPost(item);
    };

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    useEffect(() => {
        axios
            .get(`${backendUrl}/post/category/welfare/all`)
            .then((res) => {
                setCategory(res.data);
                // handleClick(res.data[0].items[0].url)
            })
            .catch((err) => {
                console.log(err);
            });
    }, [backendUrl]);

    useEffect(() => {
        if (category.length > 0) {
            handleClick(category[0].items[0].url);
        }
    }, [category]);

    return (
        <section id="blog">
            <div className="page-title">
                <h1>{translate("新生福利")}</h1>
            </div>
            <Container className="blog">
                <Container>
                    <Row>
                        <Col md="3">
                            <Accordion defaultActiveKey={0}>
                                {category.map((card, index) => (
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            variant="link"
                                            eventKey={index}
                                        >
                                            {card.header}
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body>
                                                {card.items.map(
                                                    (item, subIndex) => (
                                                        <>
                                                            {subIndex !== 0 && (
                                                                <hr />
                                                            )}
                                                            <li
                                                                key={subIndex}
                                                                value={item.url}
                                                                onClick={() =>
                                                                    handleClick(
                                                                        item.url
                                                                    )
                                                                }
                                                            >
                                                                {item.text}
                                                            </li>
                                                        </>
                                                    )
                                                )}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </Col>
                        <Col md="9">
                            <div className="blog-item">
                                <div className="blog-content">
                                    <Post postUrl={post} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <ScrollButton />
        </section>
    );
};
export default WelfarePage;
