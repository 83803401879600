import React from "react";
import {Link} from "react-router";
import {Accordion,Card} from "react-bootstrap"
import "../css/card.css"
import './career.css'
import { translate } from '../general/translate'

export const SideNavGroup = (props) => {
    return (
        <Accordion defaultActiveKey="0">
        <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
            {translate("CGSA 职业")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
            <Card.Body>
                <li>
                    <Link to={"/career/cgsa_career1"} activeClassName={"active"}>{translate("USC CGSA 职业发展部")}</Link>
                </li>
                
            </Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
            {translate("过往活动")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
            <Card.Body>
                <li>
                    <Link to={"/career/coffeechat"} activeClassName={"active"}>Coffee Chat</Link>
                </li>
                <hr />
                <li>
                    <Link to={"/career/photobooth"} activeClassName={"active"}>{translate("职业照")}</Link>
                </li>
                <hr />
                <li>
                    <Link to={"/career/masks"} activeClassName={"active"}>{translate("口罩派发")}</Link>
                </li>
            </Card.Body>
            </Accordion.Collapse>

            
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="2">
            {translate("求职资源")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
            <Card.Body>
                <li>
                    <Link to={"/career/school_resource"} activeClassName={"active"}>{translate("学校资源")}</Link>
                </li>
                <hr />
                {/* <li>
                    <Link to={"/career/china_resource"} activeClassName={"active"}>国内资源</Link>
                </li>
                <hr /> */}
                <li>
                    <Link to={"/career/us_resource"} activeClassName={"active"}>{translate("美国资源")}</Link>
                </li>
                <hr />
                <li>
                    <Link to={"/career/hiring_season"} activeClassName={"active"}>{translate("招聘季")}</Link>
                </li>
            </Card.Body>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" style={{border:'0px'}}>
            {translate("签证资讯")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
            <Card.Body>
                <li>
                    <Link to={"/career/cptopt"} activeClassName={"active"}>{translate("CPT/OPT")}</Link>
                </li>
                <div className='subNavList'>
                    <li>
                        <Link to={"/career/cptopt#cpt_people"} activeClassName={"active"}>{translate("CPT适用人群")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/cptopt#cpt_apply"} activeClassName={"active"}>{translate("申请CPT")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/cptopt#opt_people"} activeClassName={"active"}>{translate("OPT适用人群")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/cptopt#opt_apply"} activeClassName={"active"}>{translate("申请OPT")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/cptopt#opt_alert"} activeClassName={"active"}>{translate("OPT注意事项")}</Link>
                    </li>
                </div>
                <hr />
                <li>
                    <Link to={"/career/h1b"} activeClassName={"active"}>{translate("H1B")}</Link>
                </li>
                <div className='subNavList'>
                    <li>
                        <Link to={"/career/h1b#h1b_intro"} activeClassName={"active"}>{translate("H-1B介绍")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/h1b#h1b_def"} activeClassName={"active"}>{translate("H-1B定义，申请要求")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/h1b#h1b_manage"} activeClassName={"active"}>{translate("H-1B配额管理")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/h1b#h1b_apply"} activeClassName={"active"}>{translate("H-1B申请流程")}</Link>
                    </li>
                    <li>
                        <Link to={"/career/h1b#h1b_alert"} activeClassName={"active"}>{translate("H-1B电子化流程以及注意事项")}</Link>
                    </li>
                </div>
                
                <hr />
                <li>
                    <Link to={"/career/visa"} activeClassName={"active"}>{translate("疫情期间美国入境政策")}</Link>
                </li>
            </Card.Body>
            </Accordion.Collapse>
            
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="4">
            {translate("求职锦囊")}
            </Accordion.Toggle>
            
            <Accordion.Collapse eventKey="4">
            <Card.Body>
                <li>
                    <Link to={"/career/tips"} activeClassName={"active"}>{translate("求职锦囊")}</Link>
                </li>
            </Card.Body>
            </Accordion.Collapse>
            
        </Card>
        </Accordion>
    );
};