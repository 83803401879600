import React from 'react'
import './career.css'

function CgsaCareer1() {
    return (
        <div className="career">
            <h4>USC CGSA 职业发展部</h4>
            <ol>
                <li>专注USC CGSA公众号，你将收获：</li>
                <li>每两周推送的求职tips，如：如何与人network、dressing code、如何修改resume、最新的招聘信息等。</li>
                <li>赶快扫码关注我们吧！</li>
            </ol>
            <img className="blogImg" src="https://usccgsa-web.s3.amazonaws.com/CareerPagePicture.jpg" alt=''/>
        </div>
    )
}

export default CgsaCareer1;
