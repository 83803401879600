import React from 'react'
import {BiArrowToTop} from "react-icons/bi";
import {BiArrowToBottom} from "react-icons/bi";

function ScrollButton() {
    const scrollTop = () =>{
        document.getElementsByClassName('App')[0].scrollIntoView({ behavior: 'smooth'});
    }
    const scrollBottom = () =>{
        document.getElementsByTagName('footer')[0].scrollIntoView({ behavior: 'smooth'});
    }
    return (
        <div className="scrollButton">
            <BiArrowToTop onClick={scrollTop}/>
            <br />
            <BiArrowToBottom onClick={scrollBottom}/>
        </div>
    )
}

export default ScrollButton
