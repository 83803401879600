import React from 'react'

function ImgFrame({ src, h}) {
    return (
        <div className="imgframe" style={{ height: h }}>
            <img className="mainimg" src={src} height={h} alt=''/>
        </div>
    )
}

export default ImgFrame