import React from 'react'
import './Depttemplate.css'
import { Accordion, Card } from "react-bootstrap"
import useTranslate from '../customHook/useTranslate'

function DeptTemplate({ index,
    dept,
    deptEn,
    img,
    detail,
    detailEn,
    respon,
    responEn,
    require,
    requireEn,
    gain,
    gainEn,
    past,
    pastEn}) {

    useTranslate();

    const renderLines = (text) => {
        return text.split('\n').map((str, index) => {
            const parts = str.split(/(\*\*.*?\*\*)/).map((part, i) => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    return <strong key={i}>{part.slice(2, -2)}</strong>;
                }
                return part;
            });
    
            return (
                <React.Fragment key={index}>
                    {parts}
                    <br />
                </React.Fragment>
            );
        });
    };

    return (
        <div className="deptBody">
            <img src={img} alt="" />
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey={index}>
                        <h5 className="dept langBlock cn"> {dept} </h5>
                        <h5 className="dept langBlock en"> {deptEn} </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index}>
                        <Card.Body>
                            <div className="deptBody langBlock cn">
                                <div>
                                    <br />
                                    <p><strong>关于我们</strong></p>
                                    <p key={index}> {detail} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock cn">
                                <div>
                                    <br />
                                    <p><strong>部门职责</strong></p>
                                    <p key={index}> {renderLines(respon)} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock cn">
                                <div>
                                    <br />
                                    <p><strong>招新要求</strong></p>
                                    <p key={index}> {renderLines(require)} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock cn">
                                <div>
                                    <br />
                                    <p><strong>个人收获</strong></p>
                                    <p key={index}> {gain} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock cn">
                                <div>
                                    <br />
                                    <p><strong>往期活动</strong></p>
                                    <p key={index}> {past} </p>
                                </div>
                            </div>

                            <div className="deptBody langBlock en">
                                <div>
                                    <br />
                                    <p><strong>About Us</strong></p>
                                    <p key={index}> {detailEn} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock en">
                                <div>
                                    <br />
                                    <p><strong>Department responsibilities</strong></p>
                                    <p key={index}> {renderLines(responEn)} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock en">
                                <div>
                                    <br />
                                    <p><strong>Recruitment requirements</strong></p>
                                    <p key={index}> {renderLines(requireEn)} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock en">
                                <div>
                                    <br />
                                    <p><strong>Personal Gain</strong></p>
                                    <p key={index}> {gainEn} </p>
                                </div>
                            </div>
                            <div className="deptBody langBlock en">
                                <div>
                                    <br />
                                    <p><strong>Past Events</strong></p>
                                    <p key={index}> {pastEn} </p>
                                </div>
                            </div>
                            <hr />
                        </Card.Body>
                        
                        
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default DeptTemplate