import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import SocialModal from './SocialModal'
import { AiFillFacebook} from 'react-icons/ai';
import Language from './Language'
// import { translate } from './translate'
import './TopBar.css'

class TopBar extends Component{
  render(){
    return (
        <div className="top-bar">
            <Container>
                <Row>
                    <Col sm="6" xs="12">
                        <div className="top-number"></div>
                    </Col>
                    <Col sm="6" xs="12">
                        <div className="social">
                            <ul className="social-share">
                                <li>
                                    <a href="https://www.facebook.com/groups/USC.CGSA/" target="_blank" rel="noopener noreferrer">
                                        <AiFillFacebook />
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="https://www.linkedin.com/company/usc-chinese-graduate-student-association/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                                        <AiFillLinkedin />
                                    </a>
                                </li> */}
                                <SocialModal social="instagram"/>
                                <SocialModal social="wechat"/>
                                {/* <li>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScpgu71Pb5fE8P10cmGEdoDdPxAEQIS4FZSb-8fbiyyoeI6gA/viewform" rel="noopener noreferrer" style={{width:"70px"}}>{translate("加入我们")}</a>
                                </li> */}
                                <li>
                                    <Language />
                                </li>
                                
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
      )
  }
}
export default TopBar;


