import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";

function Post({ postUrl }) {
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (postUrl.length !== 0) {
           // setUrl(`http://144.202.113.228:8081/${postUrl}.html`);
            setUrl(`https://usccgsa-web.s3.amazonaws.com/activity/${postUrl}.html`);
        }
    }, [postUrl]);

    return (
        <div>
            <Iframe url={url} width="100%" height="2000px" />
        </div>
    );
}

export default Post;
