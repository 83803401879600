import React from "react";

export class Content extends React.Component {
    render() {
        return (
            <section>
                {this.props.children}
            </section>
        );
    }
}
