import React from 'react'
import './eventCard.css'
import useTranslate from '../../customHook/useTranslate'

const imgStyle={
    display: "flex",
    width: "50%"

}
function PhotoBooth() {
    useTranslate();

    return (
        <>  
            <div className='eventCard langBlock cn'>
                <h4>职业照</h4>
                <p>一张日常但又不失专业的职业照会让你的profile在Lindedin的众多小伙伴中脱颖而出！为了让大家都能拥有一张完美的职业照，在每学期的开始，USC CGSA职业发展部都会为同学们免费拍摄职业照</p>
                <p>2020年2月10日下午，职发部为同学们在Mudd Hall of Philosophy Courtyard拍摄了职业照。拍摄过程中参与活动的同学们可以和职发部的部员们不断交流和反馈，拍摄出自己最喜欢的照片，最后的成品将通过邮件单独发送给参与活动的大家。</p>
                
                <div style={imgStyle}>
                    <img style={{marginRight:'1px'}}src={require('./image/photobooth1.png').default} alt=""/>
                    <img src={require('./image/photobooth2.png').default} alt=""/>
                </div>
            </div>
            <div className='eventCard langBlock en'>
                <h4>Photo Booth</h4>
                <p>A professional profile photo will help you stand out on Linkedin! In order for everyone to have the perfect professional headshot, at the beginning of each semester, USC CGSA’s Career Development Group will take free professional profile photos for all USC students!</p>
                <p>On the day of the event, Career Development Group took career head portraits for students in the Mudd Hall of Philosophy Courtyard. Photos will be sent to students by email afterwards.</p>
                <div style={imgStyle}>
                    <img style={{marginRight:'1px'}}src={require('./image/photobooth1.png').default} alt=""/>
                    <img src={require('./image/photobooth2.png').default} alt=""/>
                </div>
            </div>
        </>
    )
}

export default PhotoBooth
