import Util from "../data_storage/ImportHtml.json";
import ImgBoardCard from "./ImgBoardCard";
// import ImgFounderCard from "./ImgFounderCard"
import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import axios from "axios";
import { translate } from "../general/translate";
import ScrollButton from "../general/ScrollButton";
import { founder } from "./util";

const AlumniPage = (props) => {
    const [data, setData] = useState([]);
    const [cards, setCards] = useState([]);
    const [historyYears, setHistoryYears] = useState([]);

    const handleClick = useCallback(
        (year) => {
            let alumni = data.filter((person) => person.priority === year);
            setCards(
                alumni.map((board, index) => (
                    <ImgBoardCard
                        key={index}
                        img={board.img}
                        linkedin={board.linkedin}
                        name={board.name}
                        nameEn={board.nameEn}
                        title={board.title}
                        titleEn={board.titleEn}
                    />
                ))
            );
        },
        [data]
    );

    const displayFounder = () => {
        setCards(
            founder.map((board, index) => (
                <ImgBoardCard
                    key={index}
                    img={board.img}
                    linkedin={board.linkedin}
                    name={board.name}
                    nameEn={board.nameEn}
                    title={board.title}
                    titleEn={board.titleEn}
                />
            ))
        );
    };

    const getYears = (data) => {
        const years = data.map((person) => parseInt(person.priority));
        const priority = [...new Set(years)].filter(
            (priority) => priority > 2000
        );
        priority.sort((a, b) => b - a);
        setHistoryYears(priority);
    };

    useEffect(() => {
        const util = Util[0].util_getpost;
        axios
            .get(util.root + util.boardmember + "/all")
            .then((res) => {
                setData(res.data);
                getYears(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // inital post
    useEffect(() => {
        if (historyYears.length > 0) {
            handleClick(`${historyYears[0]}`);
        }
    }, [historyYears, handleClick]);

    return (
        <section id="blog">
            <div className="page-title">
                <h1>{translate("CGSA 校友")}</h1>
            </div>
            <Container className="blog">
                <Container>
                    <Row>
                        <Col md="3">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        variant="link"
                                        eventKey="0"
                                    >
                                        {translate("CGSA 校友")}
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            {historyYears.map((year, index) => (
                                                <>
                                                    {index !== 0 && (
                                                        <hr
                                                            key={`separater_${index}`}
                                                        />
                                                    )}
                                                    <li
                                                        key={`academicYear_${index}`}
                                                        onClick={() =>
                                                            handleClick(
                                                                `${year}`
                                                            )
                                                        }
                                                    >
                                                        {`${year}-${year + 1}`}{" "}
                                                        {translate("学年")}
                                                    </li>
                                                </>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>

                                    <Accordion.Toggle
                                        as={Card.Header}
                                        variant="link"
                                        eventKey="1"
                                    >
                                        Founder
                                    </Accordion.Toggle>

                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            <li onClick={displayFounder}>
                                                Founder
                                            </li>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                        <Col md="9">
                            <div className="blog-item">
                                <div className="blog-content deptImg">
                                    {cards}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <ScrollButton />
        </section>
    );
};
export default AlumniPage;
