import React from 'react'
import './career.css'

function HiringSeason() {
    return (
        <div className="career">
            <img className="blogImg" src={require('./image/hiringSeason.png').default} alt='' style={{margin:'10px 20px'}}/>
            <ul>
                <li>
                    <h5>美国招聘季</h5>
                    <ul>
                        <li>秋招：Early Fall</li>
                        <p>很多冬季毕业的学生可以在秋季Career Fair上拿到offer，这时候招聘机会最多</p>
                        <li>春招：2-4月</li>
                        <p>这时候一般是公司在春季Career Fair上筛选出暑期实习生</p>
                        <li>补招：8-11月</li>
                        <p>这时属于企业年底补招，相对于前两个招聘季规模较小</p>
                    </ul>
                </li>
                <li>
                    <h5>国内招聘季</h5>
                    <ul>
                        <li>秋招：9-12月</li>
                        <li>春招：3-5月 </li>
                        <p>一般来说春招相比秋招规模小、岗位少，所以建议要找工作的大家抓紧在这个秋天找到工作！</p>
                        <li>外企补录：12-1月</li>
                        <li>暑期实习生招聘：6-9月</li>
                        <p>注：对于留学生来说，从毕业后的两年内都是应届生身份，均可参加校招。</p>
                    </ul>
                </li>
                <li><h5>注：对于留学生来说，从毕业后的两年内都是应届生身份，均可参加校招。</h5></li>
            </ul>
        </div>
    )
}

export default HiringSeason;
