import React from 'react'
import {Link} from 'react-router'
import { translate } from './translate'

function CarouselCaption({header, link, desc}) {
    return (
        <div className="carouselCaption">
            <h1><Link to={link}>{translate(header)}</Link></h1>
            <p>{translate(desc)}</p>
        </div>
    )
}

export default CarouselCaption
