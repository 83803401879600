import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap';
import './CarouseSlide.css'
import CarouselCaption from './CarouselCaption'

class CarouselSlide extends Component{
    render() {
        return (
        <section className="carouselSlide">
            <Carousel>
            <Carousel.Item>
                <a href="/about_us">
                    <img
                    className="d-block w-100 bg1"
                    src="https://usccgsa-web.s3.us-east-1.amazonaws.com/slider/all_members.JPG"
                    alt="First slide"
                    />
                </a>
                <Carousel.Caption>
                    <CarouselCaption
                        header="我们是谁"
                        
                        desc="我们是南加州大学中国研究生学生会，简称USC-CGSA。我们的宗旨是为南加大的中国研究生，博士生，MBA 提供一个专注于就业，学术和社交相关的平台，并为广大留学生提供力所能及的帮助。"
                    />
                
                </Carousel.Caption>
            </Carousel.Item>
           
            <Carousel.Item>
                {/* <a href="/welfare"> */}
                    <img
                    className="d-block w-100 bg2"
                    src="https://usccgsa-web.s3.us-east-1.amazonaws.com/slider/xingzheng_beach.jpg"
                    alt="Second slide"
                    />
                {/* </a> */}
                <Carousel.Caption>
                    <CarouselCaption
                        header="2024 CGSA Beach party 海滩派对by行政部"
                        
                        desc="在海滩上享受阳光，沙滩，海浪，和美食，度过了一个愉快的下午。"

                    />
                </Carousel.Caption>
            </Carousel.Item>
           
            <Carousel.Item>
                {/* <a href="/welfare"> */}
                    <img
                    className="d-block w-100 bg2"
                    src="https://usccgsa-web.s3.us-east-1.amazonaws.com/slider/zhongqiu.JPG"
                    alt="Second slide"
                    />
                {/* </a> */}
                <Carousel.Caption>
                    <CarouselCaption
                        header="2024 CGSA 中秋节"
                        
                        desc="在中秋节活动中，我们准备了月饼，奶茶，和各种游戏，让大家感受到了节日的氛围。"

                    />
                </Carousel.Caption>
            </Carousel.Item>
            </Carousel>
        </section>
            
        );
    }
}
export default CarouselSlide;
