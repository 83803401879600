import React from 'react'
import './career.css'
import useScroll from '../customHook/useScroll'

function H1b(props) {
    useScroll(props.location.hash);

    return (
        <div className="career">
            <h5>H-1B</h5>
            <p>H-1B电子注册流程：
                <br />
                <a style={{backgroundColor:'yellow'}} href="https://www.uscis.gov/working-in-the-united-states/temporary-workers/h-1b-specialty-occupations-and-fashion-models/h-1b-electronic-registration-process">
                https://www.uscis.gov/working-in-the-united-states/temporary-workers/h-1b-specialty-occupations-and-fashion-models/h-1b-electronic-registration-process
                </a>
            </p>
            <img className="blogImg" src={require('./image/h1b1.png').default} alt=''/>
            <p className="imgCaption">image source: newindianexpress</p>
            <h5 id="h1b_intro">H-1B介绍</h5>
            <ul>
                <li>
                    <p>
                    H-1B是美国签证的一种，指的是特殊专业人员/临时工作签证Specialty Occupations/ Temporary Worker Visas (H-1B)。
                    H-1B签证是美国最主要的工作签证类别，发放给美国公司雇佣的外国籍有专业技能的员工，属于非移民签证的一种。
                    H-1B成立的意图适用于解决是美国公司急需但在其国内又招不到的人才。因此，它对申请人的学历有一定要求，必须具备学士或以上学位。
                    </p>
                </li>
                <li>
                    <p>
                    持有H-1B签证者可以在美国工作三年，然后可以再延长三年，6年期满后如果签证持有者的身份还没有转化，就必须离开美国。
                    大多数H-1B的持有者都希望能在这段时间内完成绿卡申请，如果失败的话，持有者需离开美国至少365天，然后才可以再次申请H-1B
                    签证。根据最新政策规定，当H-1B持有人失去工作后，可以获得长达60天的宽限期（Grace Period），在此以期间以合法留美，
                    计划下一步。

                    </p>
                </li>
                <li>
                    <p>
                    还有一项特别规定：雇主如果在 H1B 签证到期之前辞退你，必须支付你「合理的」回程交通费用 
                    (reasonable costs of your return transportation)，倘若是自行离职则不在此限内。
                    如果雇主没有遵守这项规定，可以联系 USCIS Service Venter 书面申诉。
                    </p>
                </li>
                
            </ul>
            <h5 id="h1b_def">H-1B定义，申请要求</h5>
            <ul>
                <li>申请人必须属于具有“特殊才能”或“专业知识”的外籍人士：
                    <ul>
                        <li>移民法对“专业工作”的定义是：此工作需要运用专业理论知识，同时要求申请人至少具有学士学位。
                            <ol>
                                <li>大学专业学士学位以上（4年以上专科教育，加上5年以上实际工作经验）</li>
                                <li>或有相当于大学专业水平工作经验（通常3年专门领域的工作经验相当于1年的</li>
                            </ol>
                        </li>
                        <li>关于特殊才能的简单解释：
                            <ul>
                                <li>根据美国移民法，特殊才能指的是在科学，艺术，教育，商业或体育方面具备非凡才能。
                                    该才能要在全国以及世界得到认可，并有大量文件记载。申请人必须是本专业领域内的领先分子。
                                    例如：申请人获得过一个世界认可的奖项，例如诺贝尔奖（或同类别奖项），或者奥林匹克竞赛的前三名，
                                    或者奥斯卡奖等。</li>
                            </ul>
                        </li>

                    </ul>
                </li>
            </ul>
            <h5 id="h1b_manage">H-1B配额管理</h5>
            <ul>
                <li>申请美国H-1B签证不需要提供托福和雅思成绩，但每年都有配额限制。按照现行法律，2003财政年度以前，
                    外国人的年度配额为195,000人。“911”以后，为防止恐怖分子入境，每年度的签证数额降低到65,000人。
                    在2007财政年度的65,000名配额中，除去智利和新加坡两个协约国的7,000名配额外，只有58,000名配额可供其他国家申请。
                    因此，每年的H-1B申请配额都非常紧张，只有四月和五月两个月时间。与此同时因为印度人的语言和技术优势，占据了大部分名额。
                </li>
                <li>申请H-1B签证，需要有美国雇主的担保。雇主必须向美国劳工部申报《劳工情况申请表》(LCA)，保证按职务通行的工资标准支付薪金和提供工作条件。
                    同时，雇主还要向美国移民局(BCIS)呈递I-129表并支付相应的律师费和申请费。
                </li>
            </ul>
            <h5 id="h1b_apply">H-1B申请流程</h5>
            <p>要申请H-1B申请人必须首先拿到一家美国公司的录用书。然后，录用你的公司要以公司的名义将你的学历，
                    简历等材料交给移民局并交大约2000多美元的申请费用去申请配额。如果你被批准拿到配额而你此时在美国境外，
                    你就要去相应的美领馆申请签证。如果你在美国境内则可以直接调整身份。</p>
            <h6>具体流程：</h6>
            <ul>
                <li>首先，美国雇主需向移民局提出"雇用外地人员工"的申请。这个过程中需要的材料分为两个部分：
                    <ol>
                        <li>一： 美国雇主
                            <ol style={{listStyleType:'decimal'}}>
                                <li>非移民雇员申请表I-129/I-129W；</li>
                                <li>H类补充表格；</li>
                                <li>公司简介：美国雇主所从事的行业、公司成立日期、员工人数、联邦报税号码、近一年的生意总额等；</li>
                                <li>雇佣合约：雇主与申请人之间的雇佣合约，包括工作性质、工作职责、工作时间、工资、福利及合约有效期等；</li>
                                <li>专业性工作证明：证明雇主所提供的工作是属于专业性工作，该工作必须由具有专业水平的人士来任职；</li>
                                <li>支付工作能力证明：证明雇主有支付工资的能力，例如：报税单、银行月结单、财务会计报表等。</li>
                            </ol>
                        </li>
                        <li>二：申请人
                            <ol style={{listStyleType:'decimal'}}>
                                <li>正面免冠照片；</li>
                                <li>个人履历表（Resume）；</li>
                                <li>个性简介（Personal Profile）；</li>
                                <li>工作经历证明：应包括过去的每一份工作的公司名称、地址、日期、职务、工作性质以及经验等；</li>
                                <li>学历证明：应包括小学至大学不同学校的名称以及结业日期，学位证书以及毕业文凭；</li>
                                <li>有关个人专业成就的文章、报导、荣誉及奖励证书、专业执照及专业水平证明等。</li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ul>
            <p>如申请成功，申请人将会获得移民局批准通知书I-797，这时即可申请赴美签证。</p>
            <p>美国政府对H-1B的工资是有法定要求的，其目的是为了防止，美国公司利用外国廉价劳工而损害美国就业人口的利益。
                举例来说，在美国硅谷，2009年要求付给一个持H-1B的初级程序员的最低年薪是66,979美元，
                三到四年经验的程序员的最低年薪是85,946美元，五年到十年经验的程序员最低年薪是104,936美元。
                更资深的软件工程师的最低年薪是123,906美元。</p>
            <p>一般来说，每年4月1公布的配额生效日期是当年的10月1日。即如果你5月拿到了配额和签证，
                你必须等到10月1日才能在美国为你的雇主工作。</p>
            <h5 id="h1b_alert">H-1B电子化流程以及注意事项</h5>
            <img className="blogImg" src={require('./image/h1b2.png').default} alt=''/>
            <p className="imgCaption">image source: techgig</p>
            <ul>
                <li>电子化流程：
                    <ol>
                        <li>注册时间：2020年3月1日-2020年3月20日开放初始注册期。</li>
                        <li>注册费用：雇主花10美元注册账号，填写公司和H1B申请人的基本信息。</li>
                        <li>电子抽签：电子抽签将取代过去每年4月的前5个工作日抽出85000份H1B申请案的旧抽签流程。</li>
                        <li>提交申请：被抽中后，雇主有90天的时间向USCIS提交H1B申请。</li>
                    </ol>
                </li>
                <li>注册时则需要以下资料：
                    <ul>
                        <li>雇主的姓名、雇主识别号码（EIN）和雇主邮寄地址；</li>
                        <li>雇主授权代表姓名、职位头以及联系方式（电话号码和邮件地址）；</li>
                        <li>受益人全名、生日、出生国家、国籍、性别以及护照号；</li>
                        <li>受益人是否持有美国教育机构硕士及以上学历；</li>
                        <li>如有雇主律师或认可代表，应递交电子版G-28表格；</li>
                        <li>USCIS注册系统要求的其他基本信息。</li>
                    </ul>
                </li>
                <li>注意事项：
                    <ul>
                        <li>由于电子化流程导致了注册时间的更改，求职者找工作的时间会提前，需得在3月之前找到工作，
                            因此加大了秋招的压力。</li>
                        <li>由于抽签门槛的降低，可能会导致H1B申请人数增长，因此降低中签的概率。</li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default H1b;
