import React from 'react';
import {Modal} from 'react-bootstrap';
import {AiFillInstagram,AiFillWechat } from 'react-icons/ai';
import ins from '../../images/ins_QR.png'
import wechat from '../../images/wechat_QR.png'

function SocialModal(props) {
    const [show, setShow] = React.useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let icon
    let img
    if (props.social ==="instagram"){
        icon = <AiFillInstagram />
        img = <img  src={ins} alt="instagram" />
    }else if(props.social ==="wechat"){
        icon = <AiFillWechat />
        img = <img  src={wechat} alt="wechat" />
    }
    return (
      <>
        <li onClick={handleShow}><a href="/#">
            {icon}
        </a></li>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Social Media</Modal.Title>
          </Modal.Header>
        <Modal.Body>{img}</Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default SocialModal