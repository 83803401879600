import React from 'react'
import DeptTemplate from './DeptTemplate';
import { translate } from '../general/translate'
import { depts } from './Depts';

function Department() {

    return (
        <div>
            <h2>{translate('部门介绍')}</h2>
             {depts.map((dept, index)=>{
                return (
                    <DeptTemplate
                        key={index}
                        index={index}
                        dept={dept.dept}
                        deptEn={dept.deptEn}
                        img={dept.img}
                        detail={dept.detail}
                        detailEn={dept.detailEn}
                        respon={dept.respon}
                        responEn={dept.responEn}
                        require={dept.require}
                        requireEn={dept.requireEn}
                        gain={dept.gain}
                        gainEn={dept.gainEn}
                        past={dept.past}
                        pastEn={dept.pastEn}
                    />
                )
            })}
        </div>
    )
}

export default Department
