import React from "react";
import {SideNavGroup} from "./SideNavGroup";
import {Container, Row, Col} from 'react-bootstrap';
import './page.css'

export class MainPage extends React.Component {
    render() {
        return (
                <Container>
                    <Row>
                        <Col md="3">
                            <SideNavGroup />
                        </Col>
                        <Col md="9">
                            <div className="blog-item">
                                <div className="blog-content">
                                    {this.props.children}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
        );
    }
}
