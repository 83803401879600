import React from 'react'
import './career.css'
import CareerCenter from './image/CareerCenter.png';
import ConnectSC from './image/ConnectSC.png'
import Viterbi from './image/Viterbi.png'

function SchoolResource() {
    alert('点击以下链接直达学校官网。');
    return (
        <div className="career">
            <div className="schoolCard">
                <a className="schoolCard__thumbnail"
                    target="_blank" rel="noopener noreferrer" 
                    href="https://careers.usc.edu/">
                        <img src={CareerCenter} alt="" /></a>
                <div className="schoolCard__info">
                    <div className="schoolCard__text">
                        <p>Career Center 汇集了各种求职相关资源: </p>
                        <p>比如从 Trojans to Trojans Initiative （<a target="_blank" rel="noopener noreferrer" href="https://usc.peoplegrove.com/">https://usc.peoplegrove.com/</a>）里面，你能找到校友资源，并和他们建立network</p>
                        <ul>
                            <li><a href="https://careers.usc.edu/trojans-to-trojans-initiative/">Trojans to Trojans Initiative</a></li>
                            <li><a href="https://careers.usc.edu/students/videos/">View Career Videos</a>
                                <ul>
                                    <li><a href="https://careers.usc.edu/students/videos/#resumecoverletter">Resume/Cover Letter</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#internships">Internship</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#ocr">On-Campus Recruiting</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#trojantalks">Trojan Talks</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#careerfair">Career Fair Success</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#interviewing">Interviewing</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#networking">Networking</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#linkedin">LinkedIn</a></li>
                                    <li><a href="https://careers.usc.edu/employers/train-your-employees/">Professional Workplace Skills</a></li>
                                    <li><a href="https://careers.usc.edu/students/videos/#finlit">Financial Literacy Conference</a></li>
                                </ul>
                            </li>
                            <li><a href="https://careers.usc.edu/students/explore-careers/">Explore Careers Related to Your Major</a></li>
                            <li><a href="https://careers.usc.edu/students/write-a-resume/">Write a Resume & Cover Letter</a>
                                <ul>
                                    <li><a href="https://careers.usc.edu/students/write-a-resume/#resume">Write Your Resume</a></li>
                                    <li><a href="https://careers.usc.edu/students/write-a-resume/#letter">Create Your Cover Letter</a></li>
                                    <li><a href="https://careers.usc.edu/students/write-a-resume/#cv">Know When to Use a Curriculum Vitae (CV)</a></li>
                                    <li><a href="https://careers.usc.edu/students/write-a-resume/#references">List of References and Recommendation Letters</a></li>
                                </ul>
                            </li>
                            <li><a href="https://careers.usc.edu/students/find-a-job/">Find and Accept a Job or Internship</a></li>
                            <li><a href="https://careers.usc.edu/students/interviewing/">Interview & Follow-Up</a></li>
                            <li><a href="https://careers.usc.edu/students/find-a-mentor/">Find a Mentor</a></li>
                            <li><a href="https://careers.usc.edu/students/make-a-career-appointment/">Make a Career Advising Appointment</a></li>
                            <li><a href="https://careers.usc.edu/students/apply-for-funding/">Apply for Funding & Scholarships</a></li>
                            <li><a href="https://careers.usc.edu/students/discover-resources/">Discover Resources for Graduate and Doctoral Students</a></li>
                            <li><a href="https://careers.usc.edu/students/international-student-resources/">Explore Resources for International Students</a></li>
                        </ul>
                        <p>还有各种视频，手把手教你如何写好一份简历和cover letter、如何找到实习、校园内的招聘活动、如何面试、如何进行network等。</p>
                    </div>
                </div>
            </div>
            <hr />
            <div className="schoolCard">
                <a className="schoolCard__thumbnail"
                    target="_blank" rel="noopener noreferrer" 
                    href="https://careers.usc.edu/connectsc/">
                        <img src={ConnectSC} alt="" /></a>
                <div className="schoolCard__info">
                    <div className="schoolCard__text">
                        <p>ConnectSC是一个找工作或者实习最方便的网站之一，大家可以在网站里找到最新招聘信息和 Trojan Talks、预约简历和 cover letter 修改等。</p>
                        <ul>
                            <li>Jobs and Internships
                                <ul>
                                    <li><a href="https://usc-csm.symplicity.com/students/app/jobs/discover">Search</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=jobs&ss=applied&mode=list&subtab=nocr">My Job Applications</a></li>
                                    <li><a href="http://network.experience.com/">Extended Job Search</a></li>
                                </ul>
                            </li>
                            <li>Events
                                <ul>
                                    <li><a href="https://usc-csm.symplicity.com/students/app/career-fairs">Career Events</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=event&ss=is&_ksl=1&mode=list">Trojan Talks</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=event&ss=ws&_ksl=1&mode=list">Workshops</a></li>
                                </ul>
                            </li>
                            <li><a href="https://usc-csm.symplicity.com/students/index.php?s=calendar&ss=app_main&mode=landing">Advising Appointment: </a>工作日的周一到周五，12：00-15：00，大家都可以以drop-in 的形式进入USC Career Center 为大家提供的线上简历、cover letter批改活动，每次批改时长10-15分钟，first cone, first serve。</li>
                            <li>Employers
                                <ul>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=employers&mode=list&ss=employers&_ksl=1">All</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=employers&mode=list&ss=favorites&_ksl=1">Favorite Employers</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=employers&mode=list&ss=mini_contacts&_ksl=1">Contact Directory</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=employers&mode=list&ss=fav_minicontacts&_ksl=1">Favorite Contacts</a></li>
                                </ul>
                            </li>
                            <li>Resources
                                <ul>
                                    <li><a href="https://usc-csm.symplicity.com/students/app/resource/pathways">Pathways</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/app/document-library">Online Resources and Handouts</a></li>
                                    <li><a href="https://usc-csm.symplicity.com/students/index.php?s=resource&ss=video_interviews&mode=list&tab=recommended">Mock Interviews</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr />
            <div className="schoolCard">
                <a className="schoolCard__thumbnail"
                    target="_blank" rel="noopener noreferrer" 
                    href="https://viterbicareers.usc.edu/">
                        <img src={Viterbi} alt="" />
                </a>
                <div className="schoolCard__info">
                    <div className="schoolCard__text">
                        <p>各个学院的资源</p>
                        <p>大家可以根据自己的情况，找到学院特有的求职资源，比如Viterbi School of Engineering的Viterbi 
                            Link （<a target="_blank" rel="noopener noreferrer" 
                            href="https://viterbicareers.usc.edu/viterbilink/">https://viterbicareers.usc.edu/viterbilink/</a>）
                            就是一个帮助Viterbi学生和校友、mentor交流职业发展、工业、学业等各方面经验的平台。</p>
                        <p>在Viterbi Link里面，你可以找到适合自己的mentor, 和同学以及校友讨论求职上遇到的问题，加入不同地区的工程师Groups</p>
                    </div>
                </div>
            </div>
            <hr />
            <div className="schoolCard">
                <a className="schoolCard__thumbnail"
                    target="_blank" rel="noopener noreferrer" 
                    href="https://mail.google.com/mail/u/0/">
                        <img src="https://img.etimg.com/thumb/msid-68666283,width-640,resizemode-4,imgsize-154909/a-15-year-long-legacy.jpg" alt="" />
                </a>
                <div className="schoolCard__info">
                    <div className="schoolCard__text">
                        <p>学校的邮件</p>
                        <p>相信开学之后大家总会受到很多很多邮件，但是邮件再多也一定要仔细查看！很多最新的seminar、career fair、实验室招人、校园内的工作等信息都会通过邮件通知大家。</p>
                    </div>
                </div>
            </div>
            <hr />
            <div className="schoolCard">
                <a className="schoolCard__thumbnail"
                    target="_blank" rel="noopener noreferrer" 
                    href="https://www.joinhandshake.com/">
                        <img src="https://blog.spcollege.edu/careers-internships/wp-content/uploads/sites/22/2018/08/Handshake.jpg" alt="" />
                </a>
                <div className="schoolCard__info">
                    <div className="schoolCard__text">
                        <p>Handshake</p>
                        <p>使用Handshake你可以搜索学校的实习或工作机会、收到直接来自于雇主的招聘和活动信息、和对你感兴趣的校友以及其他employees建立联系、通过其他学生的评论了解你想要进入的行业。</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolResource;
