import React, { useState, useEffect } from "react";
import "./navBarCss.css";
import { translate } from "../translate";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { navItems } from "./navItems";

function NavBar() {
    const [name, setName] = useState("home");

    useEffect(() => {
        const path = window.location.pathname;
        const currentLink = navItems.filter((navItem) =>
            navItem.link === ""
                ? navItem.subNav.map((subNav) => subNav.link).includes(path)
                : navItem.link === path
        );
        setName(currentLink.length === 1 ? currentLink[0].name : "home");
    }, []);

    return (
        <div>
            <Navbar expand="md" className="navBarRow">
                <Navbar.Toggle style={{ margin: "10px 26px" }} />
                <Navbar.Collapse
                    className="navBarContent"
                    style={{ padding: "0px 3%" }}
                >
                    <Nav
                        className="justify-content-end"
                        style={{ width: "100%" }}
                    >
                        {navItems.map((navItem, index) =>
                            navItem.subNav.length === 0 ? (
                                <Nav.Link
                                    key={`nav_${index}`}
                                    href={navItem.link}
                                    className={`navItem ${
                                        name === navItem.name ? "active" : ""
                                    }`}
                                    onClick={() => setName(navItem.name)}
                                >
                                    {translate(navItem.nav)}
                                </Nav.Link>
                            ) : (
                                <NavDropdown
                                    key={`navDropdown_${index}`}
                                    title={translate(navItem.nav)}
                                    className={`${
                                        name === navItem.name ? "active" : ""
                                    }`}
                                >
                                    {navItem.subNav.map(
                                        (subNavItem, subIndex) => (
                                            <NavDropdown.Item
                                                key={`nav_${index}_${subIndex}`}
                                                href={subNavItem.link}
                                                onClick={() =>
                                                    setName(navItem.name)
                                                }
                                            >
                                                {translate(subNavItem.nav)}
                                            </NavDropdown.Item>
                                        )
                                    )}
                                </NavDropdown>
                            )
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}

export default NavBar;
