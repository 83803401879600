import React from 'react';
import Media from 'react-bootstrap/Media';
import ux from '../images/department/ux.svg';
import web from '../images/department/web.svg';
import motion from '../images/department/motion.svg';
import mobile_ui from '../images/department/mobile-ui.svg';
import web_app from '../images/department/web-app.svg'
import useTranslate from './customHook/useTranslate'

const leftAlign = {
  textAlign:'left'    
}
const imageMapping = {0:ux, 1:web, 2:motion, 3:mobile_ui,4:web_app, 5:ux}

function DepartmentInfo({index, department, departmentEn, description, descriptionEn}) {
  useTranslate();

  return (
    <Media>
        <img
          width={51}
          height={51}
          className="mr-3"
          src={imageMapping[index]}
          alt="place"
        />
        <Media.Body style={leftAlign} className="langBlock cn">
          <h3><strong>{department}</strong></h3>
          <p>{description}</p>
        </Media.Body>
        <Media.Body style={leftAlign} className="langBlock en">
          <h3 style={{maxWidth: '150px'}}><strong>{departmentEn}</strong></h3>
          <p>{descriptionEn}</p>
        </Media.Body>
      </Media>
  )
}

export default DepartmentInfo

