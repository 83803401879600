import React from 'react'
import { translate } from '../general/translate'
import useTranslate from '../customHook/useTranslate'

function ImgBoardCard({img, linkedin, name, nameEn, title, titleEn, college, major}) {
    useTranslate()
    return (
        <div>
            <div className="committee-img recent-work-wrap">
                <img src={img} alt="" />
                <div className="overlay overlay-committee">
                    <a href={linkedin} target="_blank" rel="noopener noreferrer" className="overlay-button">
                        <i className="fab fa-linkedin fa-3x linkedin-icon"></i>
                    </a>
                </div>
            </div>
            <div className="committee-content langBlock cn">
                <p>姓名：{name}</p>
                <p>职务：{title}</p>
                <p>学院：{college}</p>
                <p>专业：{major}</p>
            </div>
            <div className="committee-content langBlock en">
                <p>{translate('姓名')}：{nameEn}</p>
                <p>{translate('职务')}：{titleEn}</p>
                <p>{translate('学院')}：{college}</p>
                <p>{translate('专业')}：{major}</p>
            </div>
        </div>
    )
}

export default ImgBoardCard
