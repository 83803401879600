import React, {useState, useEffect} from 'react'

function Language() {
    const [lang, setLang] = useState('');

    useEffect (()=>{
        if (localStorage.getItem('lang') === null){
            localStorage.setItem('lang','cn')
            setLang('cn')
        }else{
            let language = localStorage.getItem('lang')
            setLang(language)
        }
    },[]);

    const changeHandler = (e) =>{
        let language = e.target.value;
        localStorage.setItem('lang',language)
        console.log(window.location.href)
        window.location.reload();
    }

    return (
        <select style={{marginLeft:'2px'}} onChange={e=>changeHandler(e)} value={lang}>
            <option value='cn'>中文</option>
            <option value='en'>English</option>
        </select>
    )
}

export default Language
