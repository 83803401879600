import React, { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import axios from "axios";
import Post from "./Post";
import "./page.css";
import { translate } from "../general/translate";
import ScrollButton from "../general/ScrollButton";

const WelfarePage = (props) => {
    const [category, setCategory] = useState([]);
    const [post, setPost] = useState({ item: null, mode: null });

    const handleClick = (item, mode) => {
        setPost({ item: item, mode: mode });
    };
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    useEffect(() => {
        axios
            .get(backendUrl + "/post/category/activity/all")
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [backendUrl]);

    // inital post
    useEffect(() => {
        if (category.length > 0) {
            let postItems = category[0].items;
            handleClick(postItems[postItems.length - 1].url, "general");
        }
    }, [category]);

    return (
        <section id="blog">
            <div className="page-title">
                <h1>{translate("CGSA 活动")}</h1>
            </div>
            <Container className="blog">
                <Container>
                    <Row>
                        <Col md="3">
                            <Accordion defaultActiveKey={0}>
                                {category.map((card, index) => (
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            variant="link"
                                            eventKey={index}
                                        >
                                            {card.header}
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body>
                                                {/* {index === 0 && <><li onClick={() => handleClick('carnival','extra')}>CGSA 嘉年华</li><hr /></> } */}
                                                {card.items
                                                    .reverse()
                                                    .map((item, subIndex) => (
                                                        <>
                                                            {subIndex !== 0 && (
                                                                <hr />
                                                            )}
                                                            <li
                                                                key={subIndex}
                                                                value={item.url}
                                                                onClick={() =>
                                                                    handleClick(
                                                                        item.url,
                                                                        "general"
                                                                    )
                                                                }
                                                            >
                                                                {item.text}
                                                            </li>
                                                        </>
                                                    ))}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </Col>
                        <Col md="9">
                            <div className="blog-item">
                                <div className="blog-content">
                                    <Post post={post} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <ScrollButton />
        </section>
    );
};
export default WelfarePage;
