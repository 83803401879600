import React, { Component } from 'react';
import {Container} from 'react-bootstrap';
import {Router,Route, browserHistory, IndexRoute} from "react-router";
import CGSAIntro from "./CGSAIntro";
import BoardIntro from "./BoardIntro";
import {MainPage} from "./MainPage";
import { translate } from '../general/translate'

import Department from './Department'

import ScrollButton from '../general/ScrollButton'


//import Background from '../../images/slider/bg1.jpg';

const topBackground={
    color:'black',
    // backgroundImage: `url(${Background})`,
    BackgroundImage: "https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/CGSAALL.jpg",
    marginBottom: '50px'
}
class AboutPage extends Component{
  render(){
    return (
        <section id="blog">
            <div className="page-title" style={topBackground}>
                <h1>{translate('关于我们')}</h1>
            </div>
            <Container className="blog">
                <Router history={browserHistory}>
                    <Route path={"/about_us"} component={MainPage} >
                        <IndexRoute component={CGSAIntro}/>
                        <Route path={"/about_us/CGSAIntro"} component={CGSAIntro} />
                        <Route path={"/about_us/BoardIntro"} component={BoardIntro} />
                        <Route path={"/about_us/Department"} component={Department} />
                    </Route>        
                </Router>  
            </Container>
            <ScrollButton />
        </section>
      
      )
  }
}
export default AboutPage;
