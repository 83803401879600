import React, {} from 'react'
import useTranslate from '../customHook/useTranslate'

function ImgBoardCard({img, linkedin, name, nameEn, title, titleEn}) {
    useTranslate();

    return (
        <div>
            <div className="committee-img recent-work-wrap">
                <img src={img} alt="" />
                <div className="overlay overlay-committee">
                    <a href={linkedin} target="_blank" rel="noopener noreferrer" className="overlay-button">
                        <i className="fab fa-linkedin fa-3x linkedin-icon"></i>
                    </a>
                </div>
            </div>
            <div className="committee-content langBlock cn">
                <p>{name}</p>
                <p>{title}</p>
            </div>
            <div className="committee-content langBlock en">
                <p>{nameEn}</p>
                <p>{titleEn}</p>
            </div>
        </div>
    
    )
}

export default ImgBoardCard
