import React from 'react'
import './career.css'

function CgsaCareer2() {
    return (
        <div className="career">
            <h4>Past Events</h4>
            <ul>
                <li>
                    <strong>Coffee Chat:</strong>
                    我们曾邀请就业于Google、安永（EY）的学长学姐，以及同时拥有纽约州和加州律师执照的师姐等来和同学们分享自己的求职经验、学习经历等，帮助同学们了解行业现状、和学长学姐建立networking。
                    <img className="blogImg" src={require('./image/coffee_chat.png').default} alt=''/>
                </li>
                <li>
                    <strong>Photo Booths:</strong>
                    上学期我们举办了Photo Booths，帮同学们拍摄了职业照。
                    <img className="blogImg" src={require('./image/photo.png').default} alt=''/>
                </li>
                <li>
                    <strong>线上招聘会：</strong>
                    我们也会和国内外的招聘平台比如China Career Center 以及猎聘、智联招聘等平台为大家推出线上招聘会。
                </li>
                <li>
                    同时也欢迎大家继续关注USC CGSA的公众号推文，积极参加我们接下来线下、线上的活动！
                </li>
                
            </ul>
        </div>
    )
}

export default CgsaCareer2;
