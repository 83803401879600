import React from 'react'
import './career.css'
import useScroll from '../customHook/useScroll'

function Cptopt(props) {
    useScroll(props.location.hash);

    return (
        <div className="career">
            <h5>CPT/OPT 介绍</h5>
            <img className="blogImg" src={require('./image/cptopt.png').default} alt='' />

            <h5>CPT (Curricular Practical Training) 课外实习训练</h5>
            <p>CPT具体信息请关注学校官网更新：
                <br />
                <a style={{ backgroundColor: 'yellow' }} href="https://ois.usc.edu/employment/employment-f1/cpt/">
                    https://ois.usc.edu/employment/employment-f1/cpt/
                </a>
            </p>
            <img className="blogImg" src={require('./image/cpt1.png').default} alt='' />
            <p>
                允许学生在校外合法打工的工作许可，且工作领域必须和学习领域相关，学生可以边工作边挣学分，
                所以很多美国大学的CPT 是可以转换一到两门学科的学分的。CPT 由学校批准且在学生的 I-20 表上注明，
                申请CPT 之前必须要有实习单位接收(Job Offer).但相对而言，就读本科或研究生的学生，使用 CPT 不是很普遍。
                拥有学生签证(F1) 且在学校学习至少一年才可以申请，CPT 可以在学期中使用，也可以在暑假或者寒假的时候使用。
            </p>

            <img className="blogImg" src={require('./image/cpt2.png').default} alt='' />

            <h5 id="cpt_people">CPT适用人群</h5>
            <ol style={{ listStyleType: 'decimal' }}>
                <li>申请人需拥有有效的F-1学生签证状态
                    <ol>
                        <li>a. 本科生申请CPT需要在美国完成9个月的全日制课程</li>
                        <li>b. 研究生一般要读满一学年后才可以申请CPT，但Annenberg: Global Communication, Marshall: Global Supply Chain Management, Cinematic Arts: Peter Stark Program等专业没有这个限制。</li>
                        <li>c. 申请 CPT 实习，需要注册学校的实习课程，不同专业有不同的要求，详情请参照官网信息。</li>
                    </ol>
                </li>
                <li>在盈利机构的有薪水及unpaid工作均需要申请CPT，申请校内工作或者校外志愿者工作是不需要CPT的</li>
                <li>申请CPT签证的学生所从事的校外工作，必须非常明确的与该学生所学习及研究的领域有着直接关系，且必须已经拿到雇主Offer</li>
                <li>申请CPT的时期必须保持full-time enrolled for the semester。</li>
                <li>实习期间，到其他州实习，需要在国际学生办公室更新住址。</li>
                <li>疫情期间如果雇主要求，学生可以远程办公或在海外办公。</li>
            </ol>

            <h5 id="cpt_apply">申请CPT</h5>
            <ol style={{ listStyleType: 'decimal' }}>
                <li>拿到公司offer (Offer上必须含有公司抬头、 地址以及确认的开始工作的时间和每周的工作小时)</li>
                <li>提交护照，I-20，工作单位的offer，I-94，CPT申请表到学校的Office of International Services (OIS）</li>
                <li>学校会根据你提供的材料，决定是否批准CPT，如果申请通过，你将会收到一份新的 I-20 上面将会显示你有CPT</li>
            </ol>

            <h5>OPT (Optional Practical Training) 选择性实践训练</h5>
            <p>OPT具体信息请关注学校官网更新：
                <br />
                <a style={{ backgroundColor: 'yellow' }} href="https://ois.usc.edu/employment/employment-f1/opt/post-completion-opt/">
                    https://ois.usc.edu/employment/employment-f1/opt/post-completion-opt/
                </a>
            </p>
            <img className="blogImg" src={require('./image/opt1.png').default} alt='' />
            <p>
                OPT 就是学生身份到工作身份的过渡阶段，OPT期间，学生仍可以保留 F1 学生签证身份，但必须在毕业之后的 60 天内
                (学生身份最后一堂课之后开始的 60 天内)申请到 OPT。如果这个期间学生找到合适的工作并与工作单位签订正式的雇佣合同，
                学生 F1 身份就能转换为 H1B 身份。普通专业 OPT 的持有时间为 12 个月，但是 STEM(Science, Technology,
                Engineering,Mathematics)项目的学生可以申请 31 个月，如果国会通过，最高能有 48 个月。申请人必须持有F1
                签证且必须在完成学位前的90 天内以及毕业之后的60 天内申请(美国国土安全局必须在学生毕业后 60 天内收到申请)
                必须在美国完成至少两个学期的全日制学习，至少在美国学习时间超过 9 个月，在美国高等院校毕业，拿到本科学士及以上学位OPT
                从事的工作必须与学生的专业背景相关，否则将视为非法工作。
            </p>

            <h5 id="opt_people">OPT适用人群</h5>
            <ol style={{ listStyleType: 'decimal' }}>
                <li>OPT申请人必须持有F-1签证且必须在完成学位前的90天内以及毕业之后的60天内申请(美国国土安全局必须在学生毕业后60天内收到申请)</li>
                <li>必须在美国完成至少两个学期的全日制学习</li>
                <li>至少在美国学习时间超过9个月</li>
                <li>在美国高等院校毕业，拿到本科学士及以上学位</li>
                <li>OPT从事的工作必须与学生的专业背景直接相关</li>
            </ol>

            <h5 id="opt_apply">申请OPT</h5>
            <ol style={{ listStyleType: 'decimal' }}>
                <li>I-765 表格，表格可以从美国国土安全局官网上下载</li>
                <li>护照信息页（护照有效期必须还有六个月以上）</li>
                <li>OPT申请表</li>
                <li>申请一份新的 I-20，获取方式请见OIS官网</li>
                <li>两张护照规格照片，照片拍摄时间必须在近三十天内，照片背面可以写上自己的姓名和 sevis号码</li>
                <li>最新的I-94，打印地址 https://i94.cbp.dhs.gov/I94/</li>
                <li>380美金的申请费，可以是支票也可以是Money Order, 收件人为”United States Department of Homeland Security”</li>
                <li>护照及所有 VISA 页的复印件</li>
                <li>所有之前申请过CPT, OPT, EAD 的同学，还需要提交CPT I-20, OPT I-20, EAD的复印件</li>
                <li>Form-1145: 通过该表格可以免费查询申请状态</li>
                <li>将以上材料准备好之后就可以到邮局取邮寄了，但是根据规定，UCSIS必须在规定日期之后的 30 天内收到申请材料，所以请务必尽早准备正确无误的材料然后尽快寄出</li>
                <li>只有在I-765表格被政府批准，并收到就业授权文件EAD（Employment Authorization Document）后，就可以开始OPT了。</li>
            </ol>

            <h5 id="opt_alert">OPT注意事项</h5>
            <ol style={{ listStyleType: 'decimal' }}>
                <li>学生可以自己选择OPT的开始日期，选择范围在[毕业日，毕业日+两个月] 。也就是说，你的OPT最早在毕业后第一天开始，最晚在毕业后的两个月开始（举例：如果毕业时间为4月30日，最晚选择的EAD生效时间为6月29日）</li>
                <li>法律规定必须在OPT生效日期开始的90日内找到工作，否则OPT就自动失效</li>
                <li>12个月内累积90天无工作状态OPT也会失效</li>
                <li>OPT的初次申请并不需要工作Offer</li>
                <li>一个等级的学位只可以申请一次OPT</li>
                <li>OPT不是独立的签证，也属于F1签证</li>
                <li>毕业旺季时，移民局OPT, EAD工卡处理时间可能较长，三个月到五个多月都有可能，因此早点着手申请OPT很有必要（举例：如果EAD卡早于6月29日收到，生效日为6月29日，结束日为次年6月29日）（举例：如果EAD卡7月20日收到，生效日为7月20日，结束日仍为次年6月29日）</li>
                <li>由于疫情影响，今年留学生们申请OPT时，最好尽量延迟OPT开始时间，这样能有更多的时间来找工作 </li>
                <li>
                    切勿踏入OPT 挂靠和虚假雇佣。 签证信息造假会影响到以后的所有的出入境的记录。一旦在以后被海关查处挂靠或者虚假雇佣，对将来申请美国的各类签证（H1B, 绿卡）都会有极大的负面影响
                    <img className="blogImg" src={require('./image/opt2.png').default} alt='' />
                </li>
                <li>对于大四毕业已经申请到研究生的同学，如果你的研究生项目在 8月底之前开学，可以不申请OPT ，留在美国等待开学；如果研究生项目将于10月份开学，假期如果留在美国，建议申请OPT作为过渡 </li>
                <li>大四或者研究生毕业正在申请工作的学生，建议尽快递交OPT申请</li>
                <li>美国移民局要在签收OPT I-20后30天内收到你的OPT申请，否则OPT I-20即为失效，须要重新向学校申请一份新的OPT I-20，重新申请</li>
                <li>Unpaid Internship不满足申请OPT Extension的条件。具体OPT Extension申请细节请查看学校的最新更新：https://ois.usc.edu/employment/employment-f1/opt/opt-stem/</li>
            </ol>


        </div>
    )
}

export default Cptopt;
