import React from 'react'
import './eventCard.css'
import useTranslate from '../../customHook/useTranslate'

function CoffeeChat1() {
    useTranslate();

    return (
        <>  
            <div className='eventCard langBlock cn'>
                <h4>9月19日 线上 Coffee Chat：和来自Ming Hsieh Department 的教授们交流科研、深造和求职</h4>
                <img src={require('./image/coffeechat1.png').default} alt=""/>
                <p>疫情期间，为了帮助同学们和教授有更好的交流，解答同学们学习和生活上的问题，Ming Hsieh Institue联合USC CGSA职发部一起举办了这次线上Coffee Chat。</p>
                <p>我们有幸邀请到了三位来自Ming Hsieh Department of Electrical and Computer Engineering的教授和大家一起讨论，他们分别是：Chia Wei (Wade) Hsu教授、Han Wang教授和Wei Wu教授。</p>
                <p>本次Coffee Chat期间同学们积极提问，提出了很多关于求学和求职的问题，教授们则根据自己的经历和经验为同学们一一解答。</p>
                <p>Coffee Chat中大家主要讨论了以下几个方面：</p>
                <h5>就业选择大公司还是start-up的主要区别是什么呢？</h5>
                <p>两种模式各有特色，重点是要结合自己的兴趣和对投资回报率的预期进行取舍。比如：大公司有更为完善的培训制度、上升空间大，但是每个人的职责分明，
                工作内容也可能相对固定；然而在start-up，公司组织结构可能更为扁平，每个人的职责范围更广泛，同时也有可能更加辛苦、升职可能更难，但同时，
                    如果你参与的start-up后期发展很好，融资上市，你作为原始股东，虽然承担的风险大，但收益也相对更高。</p>
                <h5>对于未来想要就业的同学，选择执教和去企业就业有什么不同呢？</h5>
                <p>去企业就业相比，选择执教的话在课题的选择上会更加自由，不会像在公司里面需要研究公司分配的特定方向；同时在企业里随着项目的变更，
                    可能会出现小组重组甚至解散的情况，相比之下在学校执教的工作就相对稳定。</p>
                <h5>如何选择一个在我毕业之后仍然发展空间很大的行业呢？</h5>
                <p>这个问题是很难预见的，我们很难保证一个研究方向能长期热门下去，但即使你在研究生或者博士阶段研究的方向在未来逐渐衰落了，
                凭借你的研究经历，你也一定可以继续在别的方向上有所建树。所以教授们再三强调了兴趣的重要性，不要盲目跟风，
                    每个人还是要根据自己的兴趣选择研究和发展方向，这样才能更有动力、做的更好。</p>
                <h5>对于面试，教授们有什么建议？</h5>
                <p>对自己的CV一定非常熟悉，确保里面提到的项目和论文是自己亲身参与、有深入理解的。比如论文，如果你不是第一作者也没有关系，
                    但你必须明确自己在这篇文章里面的贡献是什么，不要选择自己只是挂名、没有深入研究的文章。</p>
                <h5>对于博士生，导师们会不会介意自己实验室的博士生在假期实习？</h5>
                <p>教授们都表示不会干预学生实习。因为首先博士生参与实习一般是再毕业前的一到两年，不会是整个实验室的所有学生都要去公司工作，
                    从而出现实验室缺人手的情况。一般每个假期选择实习的学生不会超过一半，实验室的工作都能正常进行，所以导师们一般是不会阻碍学生们去实习的。</p>
                <p>以上就是我们2020年9月19日Cloud Coffee Chat的活动总结了！非常感谢Professor Chia Wei (Wade) Hsu, Professor Han Wang和
                Professor Wei Wu来和大家分享交流！想要关注更多职业相关活动和求职资源，请扫面网页底部二维码，关注CGSA微信公众号！
                </p>
            </div>
            <div className='eventCard langBlock en'>
                <h4>09/19/2020 Cloud Coffee Chat：Connecting with professors from Ming Hsieh Department!</h4>
                <img src={require('./image/coffeechat1.png').default} alt=""/>
                <p>During the coronavirus pandemic, in order to connect students and professors and answer students' questions about school and career development, Ming Hsieh Institute and USC CGSA Career Development Department co-organized a Cloud Coffee Chat.</p>
                <p>We invited three amazing professors from Ming Hsieh Department of Electrical and Computer Engineering: Professor Chia Wei (Wade) Hsu, Professor Han Wang and Professor Wei Wu.</p>
                <p>During this Coffee Chat, students asked a lot of questions on academics and career. Based on their knowledge and experiences, professors kindly shared their advice.</p>
                <p>Questions raised at the meeting such as:</p>

                <h5>What are the differences between working for a big company and a start-up?</h5>
                <p>Both of these two choices have pros and cons. The point is taking your interests and expectations of return on your investment into account.</p>
                <p>For example, big companies have better developed training systems and more opportunities for promotion, but employees’ responsibilities are clearly defined, and job duties may be relatively fixed. However, in a start-up, the organizational structure may be flatter that means the networking is easier but it is also harder to get promoted. Everyone's job duties may be more diverse but complex. </p>
                <p>The other aspect is that if the start-up you are participating in has a good development, for example, your start-up goes public. Then as an original shareholder, you will benefit more but also bear higher risks.</p>
                <p>So both big companies and start-ups are good choices, it is always a trade-off based on your goals and situation.</p>

                <h5>What is the difference between working as a professor in a university and working for a company in the private sector?</h5>
                <p>Compared with working in a company, you will have more freedom in choosing topics of your research in the university whereas when you work for a company, you need to work on specific tasks and goals given by your leaders. Teaching in a university is stabler compared to working for a company where there are risks of unemployment.</p>

                <h5>How to choose an industry that will continue to grow even after I graduate?</h5>
                <p>It is difficult for us to guarantee that an industry will continue to be popular or profitable for a long time. However, even if your master’s or PhD degree gradually falls out of popularity after your graduation, with your research experience and skills, you can definitely continue to perform very well in other directions.</p>

                <h5>Do you have any advice for interviews?</h5>
                <p>I would practice to be comfortable talking about your CV and make sure that the projects and papers mentioned in your resume were things that I was involved in and deeply understood of. For example, it doesn’t matter if you are not the first author, but you must be clear about what is your contribution in that paper. Never post a paper you are not deeply involved in.</p>

                <h5>How would PhD advisors feel about their doctoral students interning at other companies during the summer break?</h5>
                <p>Professors all stated that they would not interfere with students’ internships. Doctoral students usually do internships in their last one or two years before graduation. Generally, about half of PhD candidates on a team would intern in summer, and the work in laboratories will be carried out by the remaining half. It would be rare to have most of the PhD candidates interning over the summer and not even people working in the lab. Therefore, professors usually allow their PhD candidates to intern.</p>
                <p>That concludes CGSA Career Development | Cloud Coffee Chat on September 19th, 2020! We would like to thank Professor Chia Wei (Wade) Hsu, Professor Han Wang and Professor Wei Wu for taking the time to share their experiences!
                </p>
                <p>Please follow us on WeChat @USC CGSA where we will have our most career-related events and career development resources by CGSA Career Development group!</p>

            </div>
        </>
    )
}

export default CoffeeChat1
