import React from 'react'
import './career.css'

function Visa() {
    return (
        <div className="career">
            <h5>疫情期间美国入境政策</h5>
            <p>美国疾病控制与预防中心（CDC）于8月18日更新的消息中显示，规定在入境美国前14天内，
                到过中国（香港和澳门特别行政区除外），爱尔兰，巴西或其他欧洲国家的旅客将被禁止入境。（
                其中，美国公民及永久居民除外，但只能通过以下机场入境：LAX, JFK, SFO, ORD, SEA ,ATL 
                ,HNL ,IAD ,DFW ,BOS, DTW ,EWR ,MIA, FLL, IAH），具体细则如下</p>
            <ul>
                <li>所有赴美旅客登机前须经手COVID-19检测及体温检测，根据检测结果，旅客有可能被拒  绝登机；</li>
                <li>所有旅客须在抵境前须完成旅行表格（持有抵境前72小时内COCID-19阴性有效证明者除外）；</li>
                <li>所有合格旅客都须在入境后进行14天自我隔离；</li>
                <li>任何未能及时完成旅行表格或自我隔离的行为将被处以罚款。</li>
            </ul>
            <img className="blogImg" src={require('./image/policy.png').default} alt=''/>
            <h5>相关建议</h5>
            <ol>
                <li>如果近期想要顺利入境美国，建议采取通过上述限制以外的国家转机并停留14天再赴美，
                    但目前由于COVID-19的广泛蔓延，不少地区及国家并不能成为赴美的第三中转地，例如：
                    <ul>
                        <li>普通内地居民无法在香港/澳门特别行政区停留满14天；</li>
                        <li>加拿大、新西兰等国家目前尚处于封锁状态；</li>
                    </ul>
                </li>
                <li>及时关注第三中转地出入境政策的变化以避免中转地滞留，如无法核实确认有关情况，
                    或第三地转机、出入境政策临时出现调整的，建议及时购买其他航空公司机票。
                </li>

            </ol>
        </div>
    )
}

export default Visa;
