import React from 'react'
import './eventCard.css'
import useTranslate from '../../customHook/useTranslate'

function CoffeeChat() {
    useTranslate();

    return (
        <>  
            <div className='eventCard langBlock cn'>
                <h5><a href="/career/coffeechat1"><strong>9月19日 线上 Coffee Chat：和来自Ming Hsieh Department 的教授们交流科研、深造和求职</strong></a></h5>
                <hr />
                <h5><a href="/career/coffeechat2"><strong>Coffee Chat 2/22</strong></a></h5>
                <hr />
                <h5><a href="/career/coffeechat3"><strong>Coffee Chat 疫情特别版</strong></a></h5>
            </div>
            <div className='eventCard langBlock en'>
                <h5><a href="/career/coffeechat1"><strong>09/19/2020 Cloud Coffee Chat：Connecting with professors from Ming Hsieh Department!</strong></a></h5>
                <hr />
                <h5><a href="/career/coffeechat2"><strong>Coffee Chat 2/22</strong></a></h5>
                <hr />
                <h5><a href="/career/coffeechat3"><strong>Cloud Coffee Chat</strong></a></h5>
            </div>
        </>
    )
}

export default CoffeeChat
