import React from "react";
import {Link} from "react-router";
import {Accordion,Card} from "react-bootstrap"
import "../css/card.css"
import { translate } from '../general/translate'

export const SideNavGroup = (props) => {
    return (
        <Accordion defaultActiveKey="0">
        <Card>
            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
            USC CGSA
            </Accordion.Toggle>
            
            <Accordion.Collapse eventKey="0">
            <Card.Body>
                <li>
                    <Link to={"/about_us/CGSAIntro"} activeClassName={"active"}>{translate('组织介绍')}</Link>
                </li>
                <hr />
                <li>
                    <Link to={"/about_us/BoardIntro"} activeClassName={"active"}>{translate('主席团介绍')}</Link>
                </li>
                <hr />
                <li>
                    <Link to={"/about_us/Department"} activeClassName={"active"}>{translate('部门介绍')}</Link>
                </li>
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        </Accordion>
    );
};