export const depts = [
    {
        dept: '活动部',
        deptEn: 'Event Planning Department （Activities Department）',
        img: 'https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/EventPlanningDepartment.jpg',
        detail: '活动部是学生会内负责组织和策划活动的核心部门。作为校园生活的调剂大师，我们致力于策划和举办一系列丰富多彩的活动，包括交友社交、学术、商业以及娱乐项目等。我们通过这些活动促进部门内部、部门之间及全校师生的交流与联系，不仅增强学生之间的合作与互助，也为大家的课余生活增添了更多的色彩和乐趣。',
        detailEn: 'The Activities Department is the core department within the Student Union responsible for organizing and planning activities. As the master of campus life, we are committed to planning and organizing a series of colorful activities, including social, academic, business and entertainment projects. Through these activities, we promote exchanges and connections within departments, between departments, and between teachers and students throughout the school. This not only enhances cooperation and mutual assistance among students, but also adds more color and fun to everyone’s extracurricular life.',
        respon: '我们曾成功举办CGSA春游、德州扑克锦标赛、线下内联、新生见面会、歌王争霸赛、线下桌游等多种活动。在即将到来的24Fall学期，我们将继续推出更多令人期待的活动。活动部是一个展现创意与才能的平台，无论是天马行空的点子还是奇思妙想，都可以在这里得到实践与实现，为大家的课余生活带来无限的欢声笑语。',
        responEn: 'We have successfully held various activities such as CGSA spring outing, Texas Hold\'em poker tournament, offline in-line, freshman meeting, singing competition, offline board games and so on. In the upcoming 24Fall semester, we will continue to launch more exciting activities. The Activity Department is a platform to showcase creativity and talent. Whether it is an unconstrained idea or a whimsical idea, it can be practiced and realized here, bringing unlimited laughter and laughter to everyone\'s after-school life.',
        require: '作为我们正在寻找的活动部新成员，我们希望你拥有：\n1.丰富的创意与策划活动的创新思维\n2.对举办活动的热情与责任心\n3.良好的沟通与团队协作能力\n4.较强的执行力与实践能力',
        requireEn: 'As a new member of the Events Department we are looking for, we want you to have:\n1.Rich creativity and innovative thinking in planning activities\n2.Passion and responsibility for organizing events\n3.Good communication and teamwork skills\n4.Strong execution and practical ability',
        gain: '在活动部，你将遇到许多志同道合的伙伴！我们是一个充满热情与凝聚力的大家庭，在新的学期里共同举办各种有趣的活动。在享受活动乐趣的同时，你还将锻炼到策划、沟通和应变能力。更重要的是，你将收获一份独特的归属感和满足感。我们相信，每一位活动部成员的热情与真诚会让你感受到家的温暖，而这里也将成为你展示想象力和创造力的最佳舞台。无论是加州阳光还是繁星夜空，都将见证我们一同创造的美好时光。快来加入我们吧，遇见更多有趣的灵魂！',
        gainEn: 'In the activity department, you will meet many like-minded partners! We are a passionate and cohesive family, organizing various interesting activities together in the new semester. While enjoying the activities, you will also develop your planning, communication and adaptability skills. More importantly, you will gain a unique sense of belonging and satisfaction. We believe that the enthusiasm and sincerity of every member of the activity department will make you feel the warmth of home, and this will also become the best stage for you to show your imagination and creativity. Whether it’s the California sunshine or the starry night sky, you will witness the wonderful times we create together. Come join us and meet more interesting souls!',
        past: 'CGSA线下内联、新生见面会、情人节活动、春游、歌王争霸赛、德州扑克锦标赛，中秋庙会、春节庙会，线下桌游等。',
        pastEn: 'CGSA offline in-line, freshmen meeting, Valentine\'s Day activities, spring outing, singing competition, Texas Hold\'em poker tournament, Mid-Autumn Temple Fair, Spring Festival Temple Fair, offline board games, etc.'
    },
    {
        dept: '行政部',
        deptEn: 'Human  Resources Department - (Administration Department)',
        img: 'https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/HumanResourcesDepartment.jpg',
        detail: '行政部是CGSA的核心枢纽，致力于内部财务管理、运营、人力资源和物资管理以及各部门之间的信息共享。 我们积极策划和统筹CGSA的各大品牌活动，例如新生见面会、新生接机服务、CGSA招新等，为留学生提供支持与帮助。在行政部工作， 大家将有机会提升团队合作，信息整理与管理能力，培养责任感和大局观， 认识很多校内外的伙伴， 并接触丰富的校友资源。',
        detailEn: 'The Administration Department is the core hub of CGSA, dedicated to internal financial management, operations, human resources and material management, and information sharing among various departments. We actively plan and coordinate CGSA\'s major brand activities, such as freshmen meeting, freshmen airport pick-up service, CGSA recruitment, etc., to provide support and assistance to international students. Working in the Administration Department, you will have the opportunity to improve teamwork, information sorting and management skills, develop a sense of responsibility and an overall perspective, get to know many partners inside and outside the school, and have access to rich alumni resources.',
        respon: '秘书组： 负责部门会议的考勤，记录，监督管理、信息汇总与传递、场地申请等。\n信息与物资管理组： 管理CGSA官方小助手的日常运作、微信群管理、邮箱管理、制作内部的Newsletter、管理物资等。\n财务管理： 负责各部门的活动经费申请、入账、记账、预算统筹、内部报销等。',
        responEn: 'Secretary Team: Responsible for attendance, records, supervision and management of department meetings, information collection and transmission, venue application, etc.\nInformation and Materials Management Group: Manage the daily operations of the CGSA official assistant, WeChat group management, mailbox management, production of internal Newsletters, management of materials, etc.\nFinancial Management: Responsible for the activity fund application, accounting, accounting, budget coordination, internal reimbursement, etc. of each department.',
        require: '有责任心和耐心，做事细心有条理。可以积极参与学生会所举办的活动，善于发现细小的问题并乐于沟通。',
        requireEn: 'Responsible and patient, careful and organized. Can actively participate in activities organized by the student union, be good at identifying small problems and be willing to communicate.',
        gain: '加入我们， 你将锻炼领导力、责任感和沟通能力， 同时可以结交许多新朋友，接触校内外的优质资源。',
        gainEn: 'By joining us, you will develop your leadership, responsibility and communication skills, and at the same time make many new friends and gain access to high-quality resources inside and outside the school.',
        past: '新生见面会、接机服务、CGSA招新、CGSA内联、总结大会等。',
        pastEn: 'New student meeting, airport pick-up service, CGSA recruitment, CGSA internal communication, summary meeting, etc.'
    },
    {
        dept: '职发部',
        deptEn: 'Career Development Department - (Ministry of Staff Development)',
        img: 'https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/CareerDepartment.jpg',
        detail: '我们致力于满足留学生在职业发展上的多元需求。针对不同的职业发展方向，我们精心举办各种活动，提供专业的求职指导和个性化的职业规划建议，全方位助力学生实现职业目标。',
        detailEn: 'We are committed to meeting the diverse needs of international students in career development. For different career development directions, we carefully organize various activities, provide professional job search guidance and personalized career planning suggestions, and help students achieve their career goals in an all-round way.',
        respon: '每学期，我们都会策划和组织丰富多彩的活动。每位部员都积极参与活动的策划和组织，深入发掘学生的求职需求，与教授和行业资深人士建立沟通桥梁，用心宣传并精心举办各类精彩活动。在这里，你不仅能帮助他人，更能在过程中提升自我，为未来职场发展积累宝贵经验。',
        responEn: 'Every semester, we plan and organize a variety of activities. Each member actively participates in the planning and organization of activities, deeply explores students’ job search needs, establishes communication bridges with professors and industry veterans, and carefully promotes and organizes various exciting activities. Here, you can not only help others, but also improve yourself in the process and accumulate valuable experience for future career development.',
        require: '具有活动组织经验，活动宣传经验，善于团队合作和与人沟通，敢于创新。',
        requireEn: 'Have experience in event organization and event promotion, be good at teamwork and communication with others, and dare to innovate.',
        gain: '在举办活动的过程中，你将有机会与大家一起探讨求职中的经验，结识行业大佬、招聘HR，获得内推机会，助力自己的职业发展。',
        gainEn: 'During the event, you will have the opportunity to discuss your job search experience with everyone, get to know industry leaders, recruit HR, and get internal referral opportunities to help your career development.',
        past: '"职为你来"系列宣讲会：\n我们的金牌活动，旨在消除中国留学生在美求职的信息差。我们邀请各行业嘉宾分享宝贵经验，并提供一对一交流机会，帮助学生深入了解职场实况。\n"南加州职业发展与交流峰会"：\n该峰会于去年正式启动首届活动，成果斐然。我们邀请了20多位各行业优秀人才，与超过100名学生共聚一堂，搭建了广阔的人脉网络平台，为同学们创造了探索多元职业机会的宝贵契机。',
        pastEn: '"Job is for You" series of seminars:\nOur gold medal event aims to eliminate the information gap for Chinese international students seeking employment in the United States. We invite guests from various industries to share their valuable experiences and provide one-on-one communication opportunities to help students gain an in-depth understanding of the reality of the workplace.\n"Southern California Career Development and Networking Summit":\nThe summit officially launched its first event last year and achieved remarkable results. We invited more than 20 outstanding talents from various industries to gather together with more than 100 students, building a broad network platform and creating valuable opportunities for students to explore diverse career opportunities.'
    },
    {
        dept: '宣发部',
        deptEn: 'Publicity and Development Department',
        img: 'https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/PublicityDevelopmentDepartment.jpg',
        detail: '宣发部是CGSA对外展示与宣传的核心部门，融合了技术与创意，致力于通过多种媒体平台传递CGSA的品牌形象与活动信息。我们的工作涵盖原创推文的编辑与设计、社交媒体运营、活动宣传、海报和视觉设计、摄影拍摄、视频剪辑，以及各类技术支持和产品的开发与维护。',
        detailEn: 'The Publicity and Distribution Department is the core department of CGSA\'s external display and publicity, integrating technology and creativity, and is committed to delivering CGSA\'s brand image and event information through a variety of media platforms. Our work covers the editing and design of original tweets, social media operations, event promotion, poster and visual design, photography and shooting, video editing, as well as various technical support and product development and maintenance.',
        respon: '**视觉设计组：**负责设计各类活动海报、3D实物展示，以及推文的视觉效果，确保所有宣传材料都具备吸引力和专业性。\n**内容创作组：**负责推文的排版、撰写及原创内容的创作，制作高质量的内容，提升CGSA在各大社交媒体平台的影响力。在你加入在宣发部之后，你将有机会掌握新媒体运营、视觉设计、内容创作、技术开发等多方面的技能，快速提升个人能力，并积累丰富的项目经验。你还将结识志同道合的伙伴，共同创造精彩内容，展示CGSA的魅力。\n**社交媒体组：**负责摄影拍摄、视频剪辑，以及CGSA社交媒体账号的运营，确保活动与品牌信息能够高效传达并广泛传播。\n**技术支持组：**负责CGSA官网、USC华人论坛、微信小程序等技术产品的开发、运营与维护，为宣发部的创意和运营提供技术保障',
        responEn: 'Visual Design Team: Responsible for designing the visual effects of various event posters, 3D physical displays, and tweets to ensure that all promotional materials are attractive and professional.\nContent Creation Team: Responsible for the layout and writing of tweets and the creation of original content, producing high-quality content and enhancing CGSA\'s influence on major social media platforms. After you join the Publicity & Distribution Department, you will have the opportunity to master new media operations, visual design, content creation, technology development and other skills, quickly improve your personal capabilities, and accumulate rich project experience. You\'ll also meet like-minded people who will co-create great content and showcase the power of CGSA.\nSocial Media Team: Responsible for photography, video editing, and CGSA social media account operations to ensure that the event and brand message can be efficiently communicated and widely disseminated.\nTechnical Support Group: Responsible for the development, operation and maintenance of technical products such as CGSA official website, USC Chinese Forum, WeChat Mini Program, etc., and provide technical support for the creativity and operation of the Publicity and Distribution Department',
        require: '对新媒体运营、摄影剪辑、视觉设计或原创内容制作有兴趣。\n热爱创新，认真负责，具备良好的时间管理、沟通和团队合作能力。\n无论是**零基础**还是**初学者**，我们都欢迎积极学习和进步的态度。\n加分项：能够熟练使用，或有对PS、Canva、秀米、PR、剪映等软件有一定了解。',
        requireEn: 'As a new member of the Events Department we are looking for, we want you to have:\n1.Rich creativity and innovative thinking in planning activities\n2.Passion and responsibility for organizing events\n3.Good communication and teamwork skills\n4.Strong execution and practical ability',
        gain: '掌握新媒体运营、摄影剪辑、视觉设计、原创内容创作等多方面知识。\n快速提升视觉设计和审美能力，丰富个人作品集。\n结识志同道合的新朋友，共同成长。',
        gainEn: 'Master the knowledge of new media operation, photography and editing, visual design, original content creation and other aspects.\nQuickly improve your visual design and aesthetic skills, and enrich your portfolio.\nMeet new like-minded people and grow together.'
    },
    {
        dept: '外联部',
        deptEn: 'Business Development Department - (External Relations Department)',
        img: 'https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/BusinessDepartment.jpg',
        detail: '你是否渴望结交更多志同道合的朋友？你是否想在校园里大展身手，为社团活动带来更多资源和支持？如果你曾梦想过自己能运筹帷幄，决胜千里，或是在各种场合中展现才能，那么CGSA外联部正是你的完美舞台。在这里，你可以实现自己的梦想，发挥自己的社交和策略能力，同时学习如何在社会中游刃有余的艺术。',
        detailEn: 'Are you eager to make more like-minded friends? Do you want to show off your skills on campus and bring more resources and support to club activities? If you have ever dreamed of being able to strategize, win thousands of miles, or show your talents in various situations, then the CGSA External Relations Department is the perfect stage for you. Here you can realize your dreams, develop your social and strategic abilities, and learn the art of navigating society.',
        respon: '外联部是学生和学联与外界社会、企业、组织沟通的的桥梁，在学联中有着至关重要的作用。主要负责对接校外资源，争取赞助支持，建立合作关系。我们不但要平衡学生和商家的利益，更要不断为研究生学生会寻找赞助和活动资金，确保CGSA的活动顺利进行，几乎每个官方活动都存在外联部的身影。',
        responEn: 'The External Relations Department is a bridge between students and the Student Union and external society, enterprises, and organizations, and plays a vital role in the Student Union. Mainly responsible for connecting with external resources, seeking sponsorship support, and establishing cooperative relationships. We not only need to balance the interests of students and businesses, but also constantly seek sponsorship and event funds for the Graduate Student Association to ensure that CGSA activities proceed smoothly. The External Relations Department is present in almost every official event.',
        require: '1. 有责任心和团队合作精神。\n2. 擅长人际交往，具有良好的沟通表达能力，能够妥善处理各种关系。\n3. 敢于挑战，勇于尝试新事物，迈出舒适圈。\n4. 能够忠于自我，勇于表达自己的想法。',
        requireEn: '1. Responsible and team spirit.\n2. Good at interpersonal communication, with good communication and expression skills, and able to properly handle various relationships.\n3. Dare to take challenges, try new things, and step out of your comfort zone.\n4. Be able to be true to yourself and have the courage to express your thoughts.',
        gain: '加入我们CGSA外联部大家庭，你讲充分接触社会资源，从零开始谈成一笔笔赞助，充分锻炼自己，让自己的能力获得质的飞跃。面对挑战，解决问题，在一次次实践中不断突破自我。与校内外的企业、机构建立联系，扩展你的人际圈。与一群有激情、有梦想的伙伴们共同努力，创造出色的校园活动。',
        gainEn: 'By joining our CGSA External Relations Department family, you will have full access to social resources, negotiate sponsorships from scratch, fully train yourself, and make a qualitative leap in your abilities. Face challenges, solve problems, and constantly break through yourself in practice. Establish connections with companies and institutions inside and outside the school to expand your network. Work together with a group of passionate and dreamy partners to create outstanding campus activities.',
        past: 'CGSA折扣卡、中秋活动、游艇舞会、新生见面会赞助、接机活动赞助等。',
        pastEn: 'CGSA discount card, Mid-Autumn Festival activities, yacht dance, freshman meeting sponsorship, airport pick-up sponsorship, etc.'
    },
]