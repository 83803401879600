import React, { useState, useEffect } from "react";
import Util from '../data_storage/ImportHtml.json';
import ImgBoardCard from './ImgBoardCard';
import axios from 'axios';
import { translate } from '../general/translate'

function BoardIntro() {
    const [cards, setCards] = useState([]);

    useEffect(() => {
        const util = Util[0].util_getpost;
        axios.get(util.root + util.boardmember + '/all')
            .then(res => {
                let president = res.data.filter(person => person.priority === "1");
                let vp = res.data.filter(person => person.priority === "2");
                let directors = res.data.filter(person => person.priority === "3");
                let board_data = president.concat(vp); 
                board_data = board_data.concat(directors);
                let imgCards = board_data.map((board, index) => {
                    return <ImgBoardCard
                        key={index}
                        img={board.img}
                        linkedin={board.linkedin}
                        name={board.name}
                        nameEn={board.nameEn}
                        title={board.title}
                        titleEn={board.titleEn}
                        college={board.college}
                        major={board.major}
                    />
                })
                setCards(imgCards)
            })
            .catch(error => {
                console.log(error)
            })

    }, [])
    return (
        <div>
            <h2>{translate("主席团成员")}</h2>
            <br />
            <p>{translate("新一届主席团成员介绍")}</p>
            <div className="single-cell deptImg" style={{ minHeight: "0px" }}>
                {cards}
            </div>


        </div>
    )
}

export default BoardIntro
