import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import CGSAcarnival from "./CGSACarnival";

function Post({ post }) {
    const [url, setUrl] = useState("");
    const [mode, setMode] = useState("general");
    const [content, setContent] = useState([]);

    useEffect(() => {
        if (post["mode"] !== null && post["mode"] === "general") {
            let postUrl = post.item;
          //  setUrl(`http://144.202.113.228:8081/${postUrl}.html`);
           setUrl(`https://usccgsa-web.s3.amazonaws.com/activity/${postUrl}.html`);

            setMode(`general`);
        } else {
            setMode(`extra`);
            if (post["item"] === "carnival") {
                setContent(<CGSAcarnival />);
            }
        }
    }, [post]);

    return (
        <div>
            {mode === "general" && (
                <Iframe url={url} width="100%" height="2000px" />
            )}
            {mode === "extra" && content}
        </div>
    );
}

export default Post;
