import React from "react";
import DepartmentList from './DepartmentList'
import BoardMemberList from './BoardMemberList'
import CarouselSlide from './general/CarouselSlide'
import Video from './general/Video'

export class home extends React.Component {
    render() {
        return (
            <div>
                <CarouselSlide />
                <Video />
                <DepartmentList />
                <BoardMemberList />
            </div>
        );
    }
}