import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

const footerStyle={
    paddingTop: "30px",
    paddingBottom: "30px",
    color: "#fff",
    backgroundColor: "#000",
    textAlign: "left"
}

class Footer extends Component{
  render(){
    return (
      <footer style={footerStyle}>
        <Container>
            <Row>
                <Col sm="6">© 2021 USC Chinese Graduate Student Association. All Rights Reserved.</Col>
            </Row>
        </Container>
      </footer>          
      )
  }
}

export default Footer;
