import {useEffect} from 'react'

function useTranslate() {
    useEffect(()=>{
        if (localStorage.getItem('lang') === null){
            localStorage.setItem('lang','cn')
            let elements = document.getElementsByClassName('cn');
            for (let i = 0; i < elements.length; i++) {
                elements[i].className += ' active';
            }
            // document.getElementsByClassName('cn')[0].classList.add('active');
        }else{
            let language = localStorage.getItem('lang');
            let elements = document.getElementsByClassName(language);
            for (let j = 0; j < elements.length; j++) {
                elements[j].className += ' active';
            }
            // document.getElementsByClassName(language)[0].classList.add('active');
        }
    })
}

export default useTranslate
