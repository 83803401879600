import React, { Component } from 'react';
import logo from '../../images/CSGA_LOGO.png';
import {Container, Row, Col} from 'react-bootstrap';
import { translate } from './translate'

const sectionStyle={
    padding: "70px 0"
}
class BottomSection extends Component{
    render() {
        return (
    <section id="bottom" style={sectionStyle}>
        <Container className="fadeInDown" data-wow-duration="1000ms" data-wow-delay="600ms">
            <Row>
                <Col md="2">
                    <a href="/" className="footer-logo">
                        <img src={logo} width="100" height="100" alt="logo" />
                    </a>
                </Col>
                <Col md="10">
                    <Row>
                        <Col sm="6" md="4">
                            <div className="widget">
                                <h3>{translate('关于我们')}</h3>
                                <ul>
                                    <li><a href="/about_us">About us</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm="6" md="4">
                            <div className="widget">
                                <h3>{translate('常用链接')}</h3>
                                <ul>
                                    <li><a href="https://www.usc.edu/" target="_blank" rel="noopener noreferrer">{translate('USC官网')}</a></li>
                                    <li><a href="https://ois.usc.edu/" target="_blank" rel="noopener noreferrer">{translate('USC OIS官网')}</a></li>
                                    <li><a href="https://www.chineseinla.com/" target="_blank" rel="noopener noreferrer">{translate('洛杉矶华人资讯网')}</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col sm="6" md="4">
                            <div className="widget">
                                <h3>{translate('开发人员')}</h3>
                                <div className="developerlist">
                                    <div>
                                        <ul>
                                            <li>{translate('陈若楠')}</li>
                                            <li>{translate('陈中泽')}</li>
                                            <li>{translate('程达')}</li>
                                            <li>{translate('胡俊豪')}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>{translate('李天宁')}</li>
                                            <li>{translate('刘俊杰')}</li>
                                            <li>{translate('徐家铭')}</li>
                                            <li>{translate('姜祎然')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
            
        );
    }
}
export default BottomSection;
