// Library
import React from 'react';
import './App.css';
import './components/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Router, Route, browserHistory, IndexRoute} from "react-router";


// Components
import Footer from './components/general/Footer'
import Header from './components/general/Header'
import TopBar from './components/general/TopBar'
import NavBar from './components/general/NavBar'
import BottomSection from './components/general/BottomSection'

// main page
import {home} from './components/home'
import {Content} from './components/Content'

// about_us page
import AboutPage from './components/about_us/AboutPage'

// welfare page
import WelfarePage from './components/welfare/WelfarePage'

// new activity page
import ActivityPage from './components/activity/ActivityPage'

// career page
import CareerPage from './components/career/CareerPage'

// Alumni page
import AlumniPage from './components/alumni/AlumniPage'

// // Carnival page
// import CarvinalPage from './components/carnival/CarnivalPage'

// Contact page
import Contact from './components/Contact'

function App() {
  return (
    <div className="App">
      <TopBar />
      <Header />
      <NavBar />
      
        <Router history={browserHistory}>
        <Route path={"/"} component={Content} >
          <IndexRoute component={home} />
          <Route path={"/"} component={home} />
          <Route path={"/about_us"} component={AboutPage} />
          <Route path={"/welfare"} component={WelfarePage} />
          <Route path={"/activity"} component={ActivityPage} />
          <Route path={"/career"} component={CareerPage} />
          <Route path={"/contact"} component={Contact} />
          <Route path={"/alumni"} component={AlumniPage} />

          <Route path={"/about_us/CGSAIntro"} component={AboutPage}  />
          <Route path={"/about_us/BoardIntro"} component={AboutPage}  />
          <Route path={"/about_us/Department"} component={AboutPage}  />

          <Route path={"/career/cgsa_career1"} component={CareerPage} />
          <Route path={"/career/school_resource"} component={CareerPage} />
          <Route path={"/career/us_resource"} component={CareerPage} />
          <Route path={"/career/hiring_season"} component={CareerPage} />
          <Route path={"/career/cptopt"} component={CareerPage} />
          <Route path={"/career/h1b"} component={CareerPage} />
          <Route path={"/career/visa"} component={CareerPage} />
          <Route path={"/career/tips"} component={CareerPage} />

          {/* CGSA Career Event */}
          <Route path={"/career/coffeechat"} component={CareerPage} />
          <Route path={"/career/coffeechat1"} component={CareerPage} />
          <Route path={"/career/coffeechat2"} component={CareerPage} />
          <Route path={"/career/coffeechat3"} component={CareerPage} />
          <Route path={"/career/photobooth"} component={CareerPage} />
          <Route path={"/career/masks"} component={CareerPage} />

          {/* <Route path={"/carnival"} component={CarvinalPage} /> */}

        </Route>

      </Router>
      <BottomSection />
      <Footer />
    </div>
  );
}

export default App;

