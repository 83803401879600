import {useEffect} from 'react'

function useScroll(hash) {
    useEffect(() => {
        let hashtag = hash.replace('#', '');
        if (hash !== ''){
            document.getElementById(hashtag).scrollIntoView({ behavior: 'smooth'});
        }
    },[hash]);
}

export default useScroll
