import React, { Component } from 'react';
import {Container} from 'react-bootstrap';
import {Router,Route, browserHistory, IndexRoute} from "react-router";
import {MainPage} from "./MainPage"
//import Background from '../../images/slider/bg1.jpg';
import { translate } from '../general/translate'
import ScrollButton from '../general/ScrollButton'

import cgsa_career1 from "./CgsaCareer1"
import cgsa_career2 from "./CgsaCareer2"
import tips from "./Tips";
import cptopt from "./Cptopt"
import h1b from './H1b'
import visa from './Visa'
import schoolResource from './SchoolResource'
// import chinaResource from './ChinaResource'
import usResource from './USResource'
import hiringSeason from './HiringSeason'

// events
import CoffeeChat from './events/CoffeeChat'
import CoffeeChat1 from './events/CoffeeChat1'
import CoffeeChat2 from './events/CoffeeChat2'
import CoffeeChat3 from './events/CoffeeChat3'
import PhotoBooth from './events/PhotoBooth'
import Masks from './events/Masks'

const topBackground={
    color:'black',
    backgroundImage: "https://usccgsa-web.s3.amazonaws.com/CGSAPhoto/CGSAALL.jpg",
    marginBottom: '50px'
}

class CareerPage extends Component{
  render(){
    return (
        <section id="blog">
            <div className="page-title" style={topBackground}>
                <h1>{translate("职业发展锦囊")}</h1>
            </div>
            <Container className="blog">
                <Router history={browserHistory}>
                    <Route path={"/career"} component={MainPage} >
                        <IndexRoute component={cgsa_career1}/>
                        <Route path={"/career/cgsa_career1"} component={cgsa_career1} />
                        <Route path={"/career/cgsa_career2"} component={cgsa_career2} />
                        <Route path={"/career/tips"} component={tips} />
                        <Route path={"/career/cptopt"} component={cptopt} />
                        <Route path={"/career/h1b"} component={h1b} />
                        <Route path={"/career/visa"} component={visa} />
                        <Route path={"/career/school_resource"} component={schoolResource} />
                        {/* <Route path={"/career/china_resource"} component={chinaResource} /> */}
                        <Route path={"/career/us_resource"} component={usResource} />
                        <Route path={"/career/hiring_season"} component={hiringSeason} />

                        {/* event */}
                        <Route path={"/career/coffeechat"} component={CoffeeChat} />
                        <Route path={"/career/coffeechat1"} component={CoffeeChat1} />
                        <Route path={"/career/coffeechat2"} component={CoffeeChat2} />
                        <Route path={"/career/coffeechat3"} component={CoffeeChat3} />
                        <Route path={"/career/photobooth"} component={PhotoBooth} />
                        <Route path={"/career/masks"} component={Masks} />
                    </Route>        
                </Router>
            </Container>
            <ScrollButton />
        </section>
      
      )
  }
}
export default CareerPage;
