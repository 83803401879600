import React from "react";
import { translate } from "./general/translate";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Contact.css";
import emailjs from "emailjs-com";

function Contact() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(
        "service_uhkyjir",
        "template_dqb5a58",
        e.target,
        "user_3sk06QrAG0uAZR8j6X0Y7"
      )
        .then((result) => {
          alert('Successful')
        },(error) => {
          alert('Error, try again.')
        }
      );
    e.target.reset();
  }
  return (
    <section id="blog">
      <div className="page-title">
        <h1>{translate("联系我们")}</h1>
      </div>
      <Container className="blog">
        <Container>
          <Row>
            <Col md="8">
              <Form onSubmit={sendEmail} className="contactForm">
                <Form.Group controlId="formBasicName">
                  <Form.Label><strong>{translate('姓名')}</strong></Form.Label>
                  <Form.Control size="lg" type="text" placeholder="Enter Name" name="name"/>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label><strong>Email</strong></Form.Label>
                  <Form.Control size="lg" type="email" placeholder="Enter Email" name="email"/>
                </Form.Group>

                <Form.Group controlId="formBasicSubject">
                  <Form.Label><strong>{translate('标题')}</strong></Form.Label>
                  <Form.Control size="lg" type="text" placeholder="Enter Subject" name="subject"/>
                </Form.Group>

                <Form.Group controlId="formBasicMessage">
                  <Form.Label><strong>{translate('消息')}</strong></Form.Label>
                  <Form.Control size="lg" as="textarea" placeholder="Enter Message" name="message"/>
                </Form.Group>

                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
            <Col md="4">
              <div className="blog-item contact">
                <div>
                  <strong>Contact Email</strong>
                  <br />
                  <a href="mailto:cgsa@usc.edu">cgsa@usc.edu</a>
                </div>
                
                <div>
                  <strong>{translate("小红书")}</strong>
                  <br />
                  <a href="https://www.xiaohongshu.com/user/profile/5f023a6a000000000101fb67?xhsshare=CopyLink&appuid=5d7498050000000001002a70&apptime=1648611119">南加州大学CGSA</a>
                </div>
                <div>
                  <strong>{translate('微信')}</strong>
                  <br />
                  <img src="https://usccgsa-web.s3.amazonaws.com/cgsahelperQRcode.JPG" alt="wechat_helper"/>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Contact;
