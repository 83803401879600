import React from 'react'
// import logo from '../../images/cgsa.ico'
import logo2 from '../../images/cgsa.png'
// import logo3 from '../../images/cgsa2.png'
import './Header.css'

function Header() {
  return (
    <div className="Header">
      <div className="Header__items">
        <div className="Header__image">
          <a href="/">
            {/* <img src={logo} alt="" /> */}
            <img src={logo2} alt="" />
            {/* <img src={logo3} alt="" /> */}
          </a>
        </div>
        <div className="Header__text">
          <h2><span style={{ color: "rgb(255, 204, 0)" }}>USC</span> Chinese Graduate</h2>
          <h2>Student Association</h2>

        </div>
      </div>
    </div>
  )
}

export default Header
