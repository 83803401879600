import translateDict from './TranslateDict.json';

export function translate(text,translateDictionary='default'){
    if (localStorage.getItem('lang') === null){
        return text;
    }else{
        let language = localStorage.getItem('lang');
        let transDict = translateDict;
        if(translateDictionary !== 'default'){
            transDict = translateDictionary;
        }
        if(language==='cn' || transDict[text]===undefined){
            if(translateDict[text]===undefined && language!=='cn'){
                console.log('[Warning] no translation for '+ text);
            }
            return text;
        }else{
            return translateDict[text][language];
        }
    }
    
}