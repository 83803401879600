import React from 'react'
import './eventCard.css'
import useTranslate from '../../customHook/useTranslate'

function CoffeeChat2() {
    useTranslate();

    return (
        <>  
            <div className='eventCard langBlock cn'>
                <h4>Coffee Chat 2/22</h4>
                <img src={require('./image/coffeechat2.png').default} alt=""/>
                <p>2月22日职发部举办了线下的Coffee Chat活动，分别邀请了四位在Law、Consulting、Engineering和Finance的精英校友分享各自成功的求职经历。同时职发部的成员们还对Coffee Chat和Net Working的基本流程进行了详细讲解以及案例分享。在Coffee Chat的轻松氛围中，同学们围在嘉宾周围积极提问，了解了很多就业后真正会遇到的情况，从而更加明确了自己今后努力的方向。同学们还要到学姐学长的联系方式，和优秀校友们建立了长期的友谊。</p>
                <img src={require('./image/coffeechat3.png').default} alt=""/>
            </div>
            <div className='eventCard langBlock en'>
                <h4>Coffee Chat 2/22</h4>
                <img src={require('./image/coffeechat2.png').default} alt=""/>
                <p>On February 22nd 2020, Career Development Group held a Coffee Chat event where four alumni working in law, consulting, engineering and finance were invited to share their experiences. At the beginning of the event, members of the Career Development Group delivered a presentation on how to expand your network through LinkedIn. Then four alumni shared their working experiences. CGSA brought Starbucks for everyone to enjoy during the Q&A session. Alumni talked about their prospective industries, candidate qualifications, and advice on how to make the most out of USC. During the event, students got lots of useful advice and alumni’s contact information.</p>
                <img src={require('./image/coffeechat3.png').default} alt=""/>
            </div>
        </>
    )
}

export default CoffeeChat2
