export const navItems = [
    {
        nav: "主页",
        name: "home",
        link: "/",
        subNav: [],
    },
    {
        nav: "关于我们",
        name: "about",
        link: "",
        subNav: [
            {
                nav: "组织介绍",
                link: "/about_us/CGSAIntro",
            },
            {
                nav: "主席团成员",
                link: "/about_us/BoardIntro",
            },
            {
                nav: "部门介绍",
                link: "/about_us/Department",
            },
        ],
    },
    {
        nav: "CGSA 校园",
        name: "campus",
        link: "",
        subNav: [
            {
                nav: "新生福利",
                link: "/welfare",
            },
            {
                nav: "CGSA 职业",
                link: "/career",
            },
            {
                nav: "CGSA 活动",
                link: "/activity",
            },
        ],
    },
    {
        nav: "CGSA 校友",
        name: "alumni",
        link: "/alumni",
        subNav: [],
    },
    {
        nav: "联系我们",
        name: "contact",
        link: "/contact",
        subNav: [],
    },
];
