import React from 'react'
import './ResourceCard.css'

function ResourceCard({image, description, link,imgStyle}) {
    return (
        <div className="resourceCard">
            <a target="_blank" rel="noopener noreferrer" href={link}>
                <img className="resourceCard__thumbnail" src={image} alt="" style={imgStyle}/>
            </a>
            
            <div className="resourceCard__info">
                <div className="resourceCard__text">{description}</div>
                
            </div>
        </div>
    )
}

export default ResourceCard
