// Usage: display board member information
import React, { Component } from 'react';
import BoardMemberInfo from './BoardMemberInfo';
import axios from 'axios'
import Util from './data_storage/ImportHtml.json';
import { translate } from './general/translate'

const sectionStyle = {
  padding: "70px 0"
}

class BoardMemberList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boardMembers: []
    }
  }

  

  componentDidMount() {
    const util = Util[0].util_getpost;
    axios.get(util.root + util.boardmember + '/all')
      .then(response => {
        let president = response.data.filter(person => person.priority === "1");
        let vp = response.data.filter(person => person.priority === "2");
        let board_data = president.concat(vp); 
        this.setState({ boardMembers: board_data })
      })
      .catch(error => {
        console.log(error)
      })
  }
  render() {
    var boardMembers = this.state.boardMembers;
    var boardMember = [];
    // console.log("-------------------------");
    // console.log(this.state.boardMembers.length);
    if (boardMembers.length > 0) {
      for (let i = 0; i < this.state.boardMembers.length; i++) {
        boardMember.push(<BoardMemberInfo 
          key={i}
          name={boardMembers[i].name}
          nameEn={boardMembers[i].nameEn}
          title={boardMembers[i].title}
          titleEn={boardMembers[i].titleEn}
          college={boardMembers[i].college}
          major={boardMembers[i].major}
          linkedin={boardMembers[i].linkedin}
          img={boardMembers[i].img} />)
      }
    }

    return (
      <section id="testimonial" style={sectionStyle}>
        <div className="container">
          <div className="center fadeInDown">
            <h2>{translate("主席团成员")}</h2>
            <p className="lead">{translate("新一届主席团成员介绍")}</p>
          </div>
          <div className="center">
            <div className="committee-grid">
              {boardMember}
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default BoardMemberList;
