import React from 'react'
import './career.css'

function Tips() {
    return (
        <div className="career">
            <ol>
                <li>
                    <h5>1. 完善linkedIn简历小妙招</h5>

                    <ul>
                        <li>
                            <h6>短链接</h6>
                            <p>
                                默认状态下，每个人的profile的公共链接都非常的长。但是通过一定的设置，
                                你可以得到一个缩短又好看的版本。具体方法是，进入profile的编辑模式，
                                然后找到公共链接，点击右侧的edit。
                            </p>
                            <img className="blogImg" src={require('./image/tips1.png').default} alt=''/>
                        </li>
                        <li>
                            <h6>不同区域的显示顺序</h6>
                            <p>
                                profile中有很多不同种类的区域，比如工作经历、教育史、奖章证书等等。
                                不同的人和不同的情况下，应该使用不同的顺序。如果你是个大学生，你可能没有什么实习经历，
                                这个时候就应该把project段放在学校的上面。如果你在找工作并且有强烈的兴趣方向，
                                就应该把summary放在最顶上，并且写上诸如 “I'm a software engineer interested in
                                Machine Learning in small to medium companies in the Bay Area” 的精确描述。
                            </p>
                            <img className="blogImg" src={require('./image/tips2.png').default} alt=''/>
                        </li>
                        <li>
                            <h6>照片</h6>
                            <p>
                                最好是找个专业的摄影师照一张。如果没有专业的正照的话，可以找一张比较正式的照片。
                            </p>
                            <img className="blogImg" src={require('./image/tips3.png').default} alt=''/>
                            <p className="imgCaption">image source: themuse</p>
                        </li>
                        <li>
                            <h6>多媒体信息展示</h6>
                            <p>
                                有些区域支持多媒体信息的展示。比如你可以把你在SlideShare的幻灯片链接放上去，
                                或者是论文之类的。这样大家可以点击这些内容，对你的影响更深刻。
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h5>2. 做完实习，或者跟别人合作项目结束，通过LinkedIn要recommendation</h5>
                    <img className="blogImg" src={require('./image/tips4.png').default} alt=''/>
                    <ul>
                        <li>
                            <p>
                                你的“推荐人”可以给你的LinkedIn账号留下评价和推荐，这样别人或者招聘的公司可以公开看到。
                                好的推荐，自然会对你很有帮助，雇主们很愿意联系被别人在LinkedIn上顶着个人信息（姓名、工作、教育经历）
                                公开来表扬的人的，而且被表扬的次数越多，说明你这人越靠谱。
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h5>3. 通过LinkedIn建立自己的网络</h5>
                    <img className="blogImg" src={require('./image/tips5.png').default} alt=''/>
                    <ul>
                        <li>
                            <p>
                                尤其是你认识的人。无论是你本科甚至高中同学，还是在美国期间认识的人，想办法建立广泛的网络，保持联系。
                                记住：无论你觉得跟人network是很pleasant还是很无聊的事情，通过认识的人帮忙投简历refer你，
                                是找工作的最佳途径。还有，建立network这种事情一定要提前做、平常就做好，不要到了需要找人帮忙的时候，
                                才想起来。
                            </p>
                        </li>
                    </ul>
                </li>
                
                <li>
                    <h5>4. Career Fair</h5>
                    <img className="blogImg" src={require('./image/tips6.png').default} alt=''/>
                    <p className='imgCaption'>image source: USC careers</p>
                    <ul>
                        <li>
                            <p>
                                在我们学校，一般一年至少组织两次大型的就业招聘会（career/job fair），春秋各一次，学校非常重视，
                                因为学校的声誉最终还是要靠就业率来检验的。一个学校的career fair有哪些公司来，可在学校官网
                                （<a href="https://careers.usc.edu/employers/career-fairs/">https://careers.usc.edu/employers/career-fairs/</a>）里面查询
                            </p>
                        </li>
                    </ul>
                </li>
                
                <li>
                    <h5>5. 找我们学校的career center来给你修改简历、mock interview</h5>
                    <img className="blogImg" src={require('./image/tips7.png').default} alt=''/>
                    <ul>
                        <li>
                            <p>
                                他们可以从具体的语言组织和语法、简历的风格、外观设计等各个方面来帮助你，career center的人可以做的很好，
                                他们本来就是学校出钱从工业界招来的有HR背景的人，熟悉工业界招聘风格。
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h5>6. 通过myvisajobs.com来查找当地办理h1b的公司</h5>
                    <ul>
                        <li>
                            <p>
                                每个国际学生就业求职，都会有同样的心酸经历：总是有些公司，一听你需要办理h1b工作签证，
                                就直接就踢你出局不予考虑。最好的办法，就是搞清楚哪些公司愿意招收国际学生，愿意给办h1b。
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <h5>7. 通过glassdoor查工资</h5>
                    <img className="blogImg" src={require('./image/Glassdoor.png').default} alt=''/>
                    <ul>
                        <li>
                            <p>
                                在拿到offer以前，你也需要了解一下目标公司的工资、待遇、面试和公司评价等情况，glassdoor.com就是这样的站点。
                            </p>
                        </li>
                    </ul>
                </li>
            </ol>

        </div>
    )
}

export default Tips
