export const founder = [
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/wendyshi.png",
    linkedin: "https://www.linkedin.com/in/wendyxshi/",
    name: "Wendy Shi",
    nameEn: "Wendy Shi",
    title: "主席",
    titleEn: "President",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/jieyuchen.png",
    linkedin:
      "https://www.linkedin.com/company/usc-chinese-graduate-student-association/mycompany/",
    name: "Jieyu(Steven) Chen",
    nameEn: "Jieyu(Steven) Chen",
    title: "财务副主席",
    titleEn: "VP of Finance",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/tangtang.png",
    linkedin: "https://www.linkedin.com/in/wendyxshi/",
    name: "Tang(Angra) Tang",
    nameEn: "Tang(Angra) Tang",
    title: "市场副主席",
    titleEn: "VP of Marketing",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/miyazhu.png",
    linkedin: "https://www.linkedin.com/in/miyaminyizhu/",
    name: "Miya Zhu",
    nameEn: "Miya Zhu",
    title: "职发副主席",
    titleEn: "VP of Career",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/jili.png",
    linkedin:
      "https://www.linkedin.com/company/usc-chinese-graduate-student-association/mycompany/",
    name: "Ji(LJ) Li",
    nameEn: "Ji(LJ) Li",
    title: "活动副主席",
    titleEn: "CO-VP of Events",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/yongcunliu.png",
    linkedin: "https://www.linkedin.com/in/yongcun-glorya-liu-884863103/",
    name: "Yongcun(Gloria) Liu",
    nameEn: "Yongcun(Gloria) Liu",
    title: "活动副主席",
    titleEn: "CO-VP of Events",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/timwong.png",
    linkedin:
      "https://www.linkedin.com/company/usc-chinese-graduate-student-association/mycompany/",
    name: "Tim Wong",
    nameEn: "Tim Wong",
    title: "活动助理主席",
    titleEn: "AVP of Events",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/xueyangwang.png",
    linkedin: "https://www.linkedin.com/in/charwang2017/",
    name: "Xueyang(Char) Wang",
    nameEn: "Xueyang(Char) Wang",
    title: "外联副主席",
    titleEn: "CO-VP of Relations",
  },
  {
    img: "https://usccgsa-web.s3.amazonaws.com/committee/biye/jiachengao.png",
    linkedin:
      "https://www.linkedin.com/company/usc-chinese-graduate-student-association/mycompany/",
    name: "Jiachen(Jim) Gao",
    nameEn: "Jiachen(Jim) Gao",
    title: "外联副主席",
    titleEn: "CO-VP of Relations",
  },
];
